<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);">
            <h2 class="brand-text text-primary ml-1"><img src="assets/images/logo/logo-text.png" alt="brand-logo" /></h2>
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-6 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" [src]="
              coreConfig.layout.skin === 'dark'
                ? 'assets/images/pages/forgot-password-v2-dark.svg'
                : 'assets/images/pages/forgot-password-v2.svg'
            " alt="Forgot password V2" />
            </div>
        </div>
        <!-- /Left Text-->
        <!-- Forgot password-->
        <div class="d-flex col-lg-6 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto" *ngIf="!otp_screen && !password_screen">
                <h2 class="card-title font-weight-bold mb-1">Forgot Password? 🔒</h2>
                <p class="card-text mb-2">Enter your email / mobile and we'll send you OTP to reset your password</p>
                <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="form-label" for="forgot-password-email">Email / Mobile<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" formControlName="email" placeholder="Enter Your Email / Mobile" aria-describedby="forgot-password-email" autofocus="" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email / Mobile is required</div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block" [disabled]="loading" tabindex="2" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Send OTP</button>

                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                        <div class="alert-body">
                            <p>{{ error }}</p>
                        </div>
                    </ngb-alert>
                </form>
                <p class="text-center mt-2">
                    <a routerLink="/login"><i data-feather="chevron-left" class="mb-25"></i> Back to login</a>
                </p>
            </div>
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto" *ngIf="otp_screen">
                <h2 class="card-title font-weight-bold mb-1 text-center">OTP Verification</h2>
                <p class="card-text mb-2 text-center">Please enter the verification code sent to your number</p>
                <form class="auth-register-form mt-2" [formGroup]="registerotpForm" (ngSubmit)="onSubmitotp()">
                    <div class="form-group text-center pb-1 pt-3">
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_two')" formControlName="otp_one" class="form-control text-center" aria-describedby="register-otp_one" tabindex="1" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_three')" formControlName="otp_two" class="form-control text-center" aria-describedby="register-otp_two" tabindex="2" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_four')" formControlName="otp_three" class="form-control text-center" aria-describedby="register-otp_three" tabindex="3" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_five')" formControlName="otp_four" class="form-control text-center" aria-describedby="register-otp_four" tabindex="4" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,'otp_six')" formControlName="otp_five" class="form-control text-center" aria-describedby="register-otp_five" tabindex="5" />
                        <input type="text" style="width: 50px;margin-right: 5px;display: inline;" (keyup)="nextBox($event,6)" formControlName="otp_six" class="form-control text-center" aria-describedby="register-otp_six" tabindex="6" />
                    </div>
                    <div class="form-group text-center">
                        <p class="text-center">
                            Don't Receive the OTP?
                            <a class="text-primary" style="font-weight: 900;" (click)="resendotp()">Resend OTP</a>
                        </p>
                    </div>
                    <center>
                        <button [disabled]="loadingOtp" class="btn btn-primary btn-block" style="max-width:300px;" tabindex="6" rippleEffect>
                        <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>VERIFY
                    </button>
                    </center>
                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="errorOtp">
                        <div class="alert-body">
                            <p>{{ errorOtp }}</p>
                        </div>
                    </ngb-alert>
                </form>
                <p class="text-center mt-2">
                    <a (click)="backScreen()"><i data-feather="chevron-left" ></i> Back to Forgot Password</a>
                </p>
            </div>

            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto" *ngIf="password_screen">
                <h2 class="card-title font-weight-bold mb-1 text-center">Change Password</h2>
                <p class="card-text mb-2 text-center">Create Your New Password Here...</p>
                <form class="auth-register-form mt-2" [formGroup]="registerPasswordForm" (ngSubmit)="onSubmitpassword()">
                    <div class="form-group">
                        <label>Enter Password<span class="text-danger">*</span></label>

                        <div class="input-group input-group-merge form-password-toggle">

                            <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submittedPassword && fp.password.errors }" placeholder="············" aria-describedby="login-password"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType }" (click)="togglePasswordTextType(0)" ></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="submittedPassword && fp.password.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submittedPassword && fp.password.errors }">
                            <div *ngIf="fp.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password<span class="text-danger">*</span></label>

                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType2 ? 'text' : 'password'" formControlName="password2" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submittedPassword && fp.password2.errors }" placeholder="············" aria-describedby="login-password2"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType2, 'icon-eye': !passwordTextType2 }" (click)="togglePasswordTextType(1)" ></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="submittedPassword && fp.password2.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submittedPassword && fp.password2.errors }">
                            <div *ngIf="fp.password2.errors.required">Confirm Password is required</div>
                        </div>
                    </div>
                    <center>
                        <button [disabled]="loadingpassword" class="btn btn-primary btn-block" style="max-width:300px;" rippleEffect>
                        <span *ngIf="loadingpassword" class="spinner-border spinner-border-sm mr-1"></span>Reset
                    </button>
                    </center>
                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="errorPassword">
                        <div class="alert-body">
                            <p>{{ errorPassword }}</p>
                        </div>
                    </ngb-alert>
                </form>
            </div>
        </div>
        <!-- /Forgot password-->
    </div>
</div>
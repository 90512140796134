import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { UnitsService } from 'app/_services/units.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UnitsComponent } from 'app/component/units/units.component';

@Component({
  selector: 'app-new-unit',
  templateUrl: './new-unit.component.html'
})
export class NewUnitComponent implements OnInit {

  public unitId = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;

   constructor(private unitsComponent: UnitsComponent, private _coreSidebarService: CoreSidebarService, private _toastrService: ToastrService, private unitsService: UnitsService, private _formBuilder: FormBuilder) { }

/**
   * Toggle the sidebar
   *
   * @param name
   */
 toggleSidebar(name): void {
  this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
}

/**
 * Submit
 *
 * @param form
 */
onSubmit() {
  this.submitted = true;
  // stop here if form is invalid
  if (this.newForm.invalid) {

  }
  else {
    this.loading = true;
    const { unitName } = this.newForm.controls;

    this.unitsService.add(unitName.value, this.unitId).subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.unitId = 0
          this.submitted = false;
          this.newForm.reset();
          this.toggleSidebar('new-sidebar');
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.unitsComponent.reloadList();
        }
        else {
          this.error = data.error;
        }

      });
  }
}

ngOnInit(): void {

  this.newForm = this._formBuilder.group({
    unitName: ['', [Validators.required]]
  });

}
get f() {
  return this.newForm.controls;
}

}

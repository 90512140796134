<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Raw Material Product Stock List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-3 col-3">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>

                                <div class="col-md-3 col-9 d-flex justify-content-start justify-content-md-end pb-1" *ngIf="currentUserRole == 'Store'">
                                    <div class="w-100">
                                        <ng-select aria-describedby="register-department_id" [(ngModel)]="departmentId" (click)="getRawMaterialFilterDepartment(departmentId)">
                                            <ng-option [value]="0" selected>All Department</ng-option>
                                            <ng-option *ngFor="let department of departmentsDropDown" [value]="department.id">{{department.name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="col-md-4 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Raw Material name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getRawMaterialFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                                <div class="col-12 d-sm-none d-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Raw Material name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getRawMaterialFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                                <div class="col-md-2 col-12  justify-content-start justify-content-md-end pb-1">

                                    <button class="btn btn-success " rippleEffect type="button" (click)="downloadReport('PDF')" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="PDF Download">
                                        <i data-feather="file" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                        <span class="d-none d-sm-inline-block">PDF</span>
                                    </button>

                                    <button class="btn btn-info ml-1" rippleEffect type="button" (click)="downloadReport('Excel')" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Excel Download">
                                        <i data-feather="file-text" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                        <span class="d-none d-sm-inline-block">Excel</span>
                                    </button>

                                </div>


                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th class="action-column">#</th>
                                            <th *ngIf="currentUserRole == 'Store'">Department</th>
                                            <th>Name</th>
                                            <th>Stock</th>
                                            <th>Transfer</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="rowMaterialProductList?.length != 0">
                                        <tr *ngFor="let rowMaterialProduct of rowMaterialProductList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td *ngIf="currentUserRole == 'Store'">{{ rowMaterialProduct.name }}</td>
                                            <td>{{ rowMaterialProduct.product_name }}</td>
                                            <td>{{ rowMaterialProduct.qty }}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm btn-info" (click)="modalOpen(modalBasic,rowMaterialProduct.id,ndx)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Stock Transfer"><i data-feather="truck"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="rowMaterialProductList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Stock Transfer</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body  overflow-auto" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="card-body pb-md-0">
                <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">
                        <div class="form-group col-6">
                            <label for="register-total_qty" class="form-label">Total Qty</label>
                            <input type="text" readonly formControlName="total_qty" class="form-control" placeholder="Total Qty" aria-describedby="register-total_qty" />
                        </div>
                        <div class="form-group col-6">
                            <label for="register-transfer_qty" class="form-label">Transfer Qty<span class="text-danger">*</span></label>
                            <input type="number" min="1" formControlName="transfer_qty" class="form-control" placeholder="Transfer Qty" aria-describedby="register-transfer_qty" [ngClass]="{ 'is-invalid': submitted && f.transfer_qty.errors }" />
                            <div *ngIf="submitted && f.transfer_qty.errors" class="invalid-feedback">
                                <div *ngIf="f.transfer_qty.errors.required">Transfer Qty is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-12">
                            <label for="register-transfer_department_id" class="form-label">Select Department<span class="text-danger">*</span></label>
                            <ng-select aria-describedby="register-transfer_department_id" formControlName="transfer_department_id" appendTo="body">
                                <ng-option [value]="" selected disabled>Select Department</ng-option>
                                <ng-option *ngFor="let department of departmentsTransferDropDown" [value]="department.id">{{department.name}}</ng-option>
                            </ng-select>
                        </div>

                        <div class="form-group col-lg-12 text-right">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" tabindex="13" rippleEffect>
                                Close
                            </button>
                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="12" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Modal -->
import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DealersService } from 'app/_services/dealers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { ProductsService } from 'app/_services/products.service';

@Component({
  selector: 'app-dealers-add',
  templateUrl: './dealers-add.component.html',
  styleUrls: ['./dealers-add.component.scss']
})
export class DealersAddComponent implements OnInit {

  public dealerId = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  isAddMode: boolean = true;
  public stateDropDown: any;
  public cityDropDown: any;
  public guestMode: boolean = false;
  public categoryDropDown: any;
  public employeeDropDown: any;

  constructor(private _authenticationService: AuthenticationService, private productsService: ProductsService,private dealersService: DealersService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
   
    if (this.route.snapshot.params['id'] !== undefined) {
      this.dealerId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.dealerId;
    if (!this.isAddMode) {
      this.edit(this.dealerId);
    }

    this.newForm = this._formBuilder.group({
      category_id: ['', Validators.required],
      employee_id: ['', Validators.required],
      enterprise_name: ['', Validators.required],
      owner_name: [null],
      phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
      landline: [null, [Validators.pattern('^[0-9]{11}')]],
      alternate_phone: [null, [Validators.pattern('^[0-9]{10}')]],
      email: [null, [Validators.email]],
      address_one: [null],
      address_two: [null],
      address_three: [null],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      pincode: [null],
      gst_no: [null],
      status: [null],
      opening_balance: [null],
      opening_balance_type: [null]
    });
    this.f.state_id.setValue(null);
    this.f.city_id.setValue(null);
    this.f.status.setValue(0);
    this.f.category_id.setValue(null);
    this.f.opening_balance_type.setValue(0);
    this.f.employee_id.setValue(null);
    this.getStateDropdown();
    this.getCategoryDropDown();
  }
  get f() {
    return this.newForm.controls;
  }


  edit(id: any) {
    this.dealersService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.dealerId = response.dealer.id;          
          this.getEmployeeDropDown(response.dealer.category_id);
          this.getCityUsingId(response.dealer.state_id);
          this.f.enterprise_name.setValue(response.dealer.name);
          this.f.owner_name.setValue(response.dealer.owner_name);
          this.f.phone.setValue(response.dealer.phone);
          this.f.landline.setValue(response.dealer.landline);
          this.f.alternate_phone.setValue(response.dealer.alternate_phone);
          this.f.email.setValue(response.dealer.email);
          this.f.address_one.setValue(response.dealer.address_one);
          this.f.address_two.setValue(response.dealer.address_two);
          this.f.address_three.setValue(response.dealer.address_three);
          this.f.state_id.setValue(response.dealer.state_id);
          this.f.city_id.setValue(response.dealer.city_id);
          this.f.pincode.setValue(response.dealer.pincode);
          this.f.gst_no.setValue(response.dealer.gst_no);
          this.f.status.setValue(response.dealer.status);
          this.f.opening_balance.setValue(response.dealer.opening_balance);
          this.f.opening_balance_type.setValue(response.dealer.opening_balance_type);
          let categories = response.dealer.category_id.split(",").map(x => Number.parseInt(x, 10));
          this.f.category_id.setValue(categories);
          this.f.employee_id.setValue(response.dealer.employee_id);
          if (response.dealer.status == 3)
            this.guestMode = true;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getStateDropdown() {
    this.dealersService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCity(event: any) {    
    this.dealersService.getCity(event).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCityUsingId(stateId: any) {
    this.dealersService.getCity(stateId).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      const { enterprise_name, owner_name, phone, alternate_phone, email, address_one, address_two, address_three, state_id, city_id, pincode, gst_no, status, opening_balance, opening_balance_type,category_id,employee_id,landline } = this.newForm.controls;

      this.dealersService.add(enterprise_name.value, owner_name.value, phone.value, alternate_phone.value, email.value, address_one.value, address_two.value, address_three.value, state_id.value, city_id.value, pincode.value, gst_no.value, status.value, opening_balance.value, opening_balance_type.value,category_id.value,employee_id.value,landline.value, this.dealerId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {

            this.dealerId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/dealers']);
          }
          else {

            if (data.error.phone) {
              this.error = data.error.phone[0];
            }
            else {
              this.error = data.error;
            }

          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  setGuestMode(event: any) {
    if (event.target.value != 3) {
      this.guestMode = false;
    }
  }
  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
  
  getEmployeeDropDown(categoryId: any){
    this.employeeDropDown = [];
    this.f.employee_id.setValue(null);
    this.dealersService.getEmployeesDropDown(categoryId).subscribe(
      data => {
        if (data.status) {
          this.employeeDropDown = data.employee;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

}

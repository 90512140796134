import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class StockReturnService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-return-list`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(return_date: any, description: any, products: any,given_id:any, id: any = ''): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}store-return`, { return_date, description, products,given_id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}store-update`, { return_date, description, products,given_id, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  viewDetails(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-return-show`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-return-delete`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  givenDropdown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-given-dropdown`, {  })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  givenRawMaretialDropdown(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-given-raw-material-dropdown`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
 
}

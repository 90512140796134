<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Purchase Order List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-3 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">

                                    </div>
                                </div>
                                <div class="col-md-3 col-6 d-flex justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0" *ngIf="currentUserRole == 'Purchase'">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/purchase-order/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Order">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> PO No.</th>
                                            <th> PO Date</th>
                                            <th> Vendor Name</th>
                                            <th> Amount</th>
                                            <th> Status</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsList?.length != 0">
                                        <tr *ngFor="let product of productsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ product.po_number }}</td>
                                            <td>{{ product.po_date }}</td>
                                            <td>{{ product.name }}</td>
                                            <td>₹ {{ product.total_amount | number:'1.2-2' }}</td>
                                            <td>
                                                <div ngbDropdown *ngIf="product.status == 0 && currentUserRole != 'Purchase'">
                                                    <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm" type="button" id="dropdownMenuButton101">
                                                        Processing
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="orderchangeStatus(product.id,4)">Approved</a>
                                                        <a ngbDropdownItem (click)="orderchangeStatus(product.id,2)">Canceled</a>
                                                    </div>
                                                </div>

                                                <div ngbDropdown *ngIf="product.status == 4 && currentUserRole != 'Purchase'">
                                                    <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                        Approved
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="orderchangeStatus(product.id,0)">Processing</a>
                                                        <a ngbDropdownItem (click)="orderchangeStatus(product.id,2)">Canceled</a>
                                                    </div>
                                                </div>

                                                <div ngbDropdown *ngIf="product.status == 2 && currentUserRole != 'Purchase'">
                                                    <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                        Canceled
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="orderchangeStatus(product.id,0)">Processing</a>
                                                        <a ngbDropdownItem (click)="orderchangeStatus(product.id,4)">Approved</a>
                                                    </div>
                                                </div>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="product.status == 0 && currentUserRole == 'Purchase'">Processing</span>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="product.status == 4 && currentUserRole == 'Purchase'">Approved</span>
                                                <span class="badge badge-pill badge-light-danger mr-1" *ngIf="product.status == 2 && currentUserRole == 'Purchase'">Canceled</span>

                                                <span class="badge badge-pill badge-light-primary mr-1" *ngIf="product.status == 1">Received</span>
                                                <span class="badge badge-pill badge-light-info mr-1" *ngIf="product.status == 3">Half Receive</span>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem (click)="download(product.id,ndx)"><i data-feather="download" class="mr-50"></i><span>Download</span></a>
                                                        <a ngbDropdownItem (click)="modalOpen(modalBasic,product.id,'view')"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                        <a ngbDropdownItem *ngIf="product.status == 4 || product.status == 3" (click)="modalOpen(modalReceive,product.id,'receive')"><i data-feather="truck" class="mr-50"></i><span>Receive</span></a>
                                                        <!-- <a ngbDropdownItem><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a> -->
                                                        <a ngbDropdownItem *ngIf="product.status == 2 || product.status == 0 || product.status == 4" (click)="delete(product.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderDetails?.length != 0">#{{ purchaseOrderDetails.po_number }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderDetails?.length == 0">Purchase Order Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="purchaseOrderDetails?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="purchaseOrderDetails?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <div class="row">
                    <div class="col-12">
                        <table class="table" style="width:100%;">
                            <tbody>
                                <tr>
                                    <td style="padding:5px;width: 50%;">
                                        <h3>Supplier :</h3><br/>
                                        <b>{{ purchaseOrderDetails.manufacturer.name }}</b><br/>
                                        <b>Mobile :</b> {{ purchaseOrderDetails.manufacturer.mobile }}<br/>
                                        <b>Email :</b> {{ purchaseOrderDetails.manufacturer.email }}<br/>
                                        <b>Address : </b> {{ purchaseOrderDetails.manufacturer.address_one }}, {{ purchaseOrderDetails.manufacturer.address_two }}, {{ purchaseOrderDetails.manufacturer.address_three }} - {{ purchaseOrderDetails.manufacturer.state
                                        }}, {{ purchaseOrderDetails.manufacturer.city }}
                                        <span *ngIf="currentUser.user.is_gst_print == 1"><b>GST : </b>{{ purchaseOrderDetails.manufacturer.gst_no | uppercase }}</span>
                                    </td>
                                    <td style="padding:5px;width: 50%;">
                                        <h3>Vendor :</h3> <br/>
                                        <b>Name :</b> {{ purchaseOrderDetails.name }}<br/>
                                        <b>Mobile :</b> {{ purchaseOrderDetails.mobile }}<br/>
                                        <b>Address :</b> {{ purchaseOrderDetails.address }}
                                        <br/><br/>

                                        <b>PO Number :</b> {{ purchaseOrderDetails.po_number }}<br/>
                                        <b>PO Date :</b> {{ purchaseOrderDetails.po_date }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-hover table-striped" style="width:100%;margin-top:15px;">
                            <thead>
                                <tr style="font-weight:bold;">
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Sr.No.</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Product</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Price</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Qty</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Remaining Qty</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let orderProduct of purchaseOrderDetails.order_details; let sr = index">
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{sr+1}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                        {{ orderProduct.product_name }}
                                        <small><br/>{{ orderProduct.category_name }}</small>
                                    </td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.price}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.qty}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.remaining_qty}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.total}}</td>
                                </tr>

                            </tbody>
                        </table>
                        <table class="table" style="width:100%;border:none;">
                            <tbody>
                                <tr *ngIf="purchaseOrderDetails.remarks != ''">
                                    <td><b>Remarks : </b> {{ purchaseOrderDetails.remarks }}</td>
                                </tr>

                                <tr>
                                    <td><b>Payment Terms : </b> {{ purchaseOrderDetails.paymentCondition }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>INSTRUCTION:</b> <br>
                                        <ol>
                                            <li>GOODS MUST SUPPLY IN STANDARD PACKING IN CASE OF NON-STANDARD & DAMAGE PACKING GOODS WILL NOT BE UNLOADING. IN CASE OF URGENCY, XRICTICAL PARTS QUALITY WILL BE UNLOADED & REMAINING ITEMS QUANTITY KEEPHOLD TILL
                                                SUPPLIERS AGREE & ASSURE IMPROVEMENT IN FUTURE SUPPLIES</li>
                                            <li>DIMENSIONAL AND CHEMICAL REPORTS ARE MANDATORY ALONG WITH EACH LOT IN CASE ANY OTHER DOCS ARE NOT SPECIFIED</li>
                                            <li>ISSUE FORM "C" WHENEVER CST IS APPLICABLE</li>
                                            <li>SHALL HAVE PO NO ON EACH INOVICE OR CHALLAN NO FOR ASSEMBLED MATERIAL IF MUST BE SUPPILED IN ASSEMBLED CONDITION ONLY MATERIAL INWARD IS RESTRICTED AFTER 07:30 PM</li>
                                        </ol>

                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:5px;background-color: #1e9dd6;text-align: center;color:rgb(194, 194, 194)">
                                        Powered By : TechFirst
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
    </div>
</ng-template>
<!-- / Modal -->


<!-- Modal Receive -->
<ng-template #modalReceive let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderReceive?.length != 0">#{{ purchaseOrderDetails.po_number }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderReceive?.length == 0">Purchase Order Receive</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="purchaseOrderReceive?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="purchaseOrderReceive?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <div class="row">
                    <div class="col-7">
                        <b>Vendor Name :</b> {{ purchaseOrderReceive.name }}
                    </div>
                    <div class="col-5 text-end text-right">
                        <b>PO Date :</b> {{ purchaseOrderReceive.po_date }}
                    </div>
                    <div class="col-12 mt-2">
                        <hr/>
                        <div class="row">
                            <div class="col-4"><b>Product</b></div>
                            <div class="col-2"><b>Price</b></div>
                            <div class="col-2 text-center"><b>Qty</b></div>
                            <div class="col-2 text-center"><b>Receive Qty</b></div>
                            <div class="col-2 text-center"><b>Remaining Qty</b></div>
                        </div>
                        <hr class="m-0 p-0" />
                        <hr/>
                    </div>
                    <div class="col-12" *ngFor="let orderProduct of purchaseOrderReceive.order_details; let sr = index">
                        <div class="row">
                            <div class="col-4">
                                {{ orderProduct.product_name }}
                                <small><br/>{{ orderProduct.category_name }}</small>
                            </div>

                            <div class="col-2 text-end">
                                {{orderProduct.price}}
                            </div>
                            <div class="col-2 text-center">
                                {{orderProduct.qty}}
                            </div>
                            <div class="col-2 text-center">
                                <core-touchspin [numberValue]="0" size="sm" [stepValue]="1" [minValue]="0" [maxValue]="orderProduct.remaining_qty" (onChange)="countChange($event,sr)"></core-touchspin>
                            </div>
                            <div class="col-2 text-center">
                                {{orderProduct.remaining_qty_new}}
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-info" (click)="onReceiveSave()"><i class="fa fa-save"></i> Save</button>
    </div>
</ng-template>
<!-- / Modal Receive -->
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ProductsService } from 'app/_services/products.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit {

  productsList: any = [];
  start: any = 0;
  page: any = 0;

  public loadingFrm = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;

  public loadingFrmStock = false;
  public errorStock = '';
  public newFormStock: FormGroup;
  public submittedStock = false;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public product_selected_file: any;
  public product_selected_name: any = '';
  public product_selected_size: any;

  public editProduct: any = [];
  public productId: any;

  public productsStockList: any = [];
  public startStock: any = 0;
  public pageStock: any = 0;

  public collectionSizeStock = 0;
  public lengthsStock = 5;
  public loadingStock = false;

  public progressMainTable: boolean = false;
  public progressStockTable: boolean = false;

  public categoryDropDown: any = [];
  public categoryFilter: any = [];

  public searchByName: any = '';

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private productsService: ProductsService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.productList(this.start, this.lengths);
    this.getCategoryDropDown();

    this.newForm = this._formBuilder.group({
      products: ['', Validators.required]
    });

    this.newFormStock = this._formBuilder.group({
      product_qty: ['', Validators.required]
    });
  }

  get f() {
    return this.newForm.controls;
  }

  get fs() {
    return this.newFormStock.controls;
  }

  productList(my_start: any, my_lengths: any) {

    this.productsService.getData(my_start, my_lengths, this.categoryFilter,this.searchByName).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.productsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productList(this.start, this.lengths);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.productsService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.productList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Product Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  // modal Basic
  modalOpen(modalBasic) {
    this.modalService.open(modalBasic, {
      size: 'md'
    });
  }

  uploadFile(event: any) {
    this.error = '';
    this.product_selected_file = event.target.files[0];
    this.product_selected_name = event.target.files[0]['name'];
    this.product_selected_size = event.target.files[0]['size'] / 1024 / 1024;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.error = 'Please Upload File';
    }
    else {
      this.error = '';
      this.loadingFrm = true;

      const formdata = new FormData();
      formdata.append('products', this.product_selected_file);

      this.productsService.uploadFile(formdata).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.modalService.dismissAll('modalBasic');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.productList(this.page, this.lengths);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });

    }
  }

  /**
 * Toggle the sidebar
 *
 * @param name
 */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  manageStock(index: any) {
    this.editProduct = this.productsList[index];
    this.productId = this.editProduct.id;
    this.productsStockList = [];
    this.collectionSizeStock = 0;
    this.toggleSidebar('new-sidebar');
    this.productStockList(this.startStock, this.lengthsStock, this.productId);
  }

  /**
   * Submit
   *
   * @param form
   */
  onSubmitStock() {
    this.submittedStock = true;
    // stop here if form is invalid
    if (this.newFormStock.invalid) {

    }
    else {
      this.loadingFrmStock = true;

      this.productsService.addStock(this.productId, this.fs.product_qty.value).subscribe(
        data => {
          this.loadingFrmStock = false;
          if (data.status) {
            this.productId = 0
            this.submittedStock = false;
            this.newFormStock.reset();
            this.toggleSidebar('new-sidebar');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this._toastrService.error(data.error.sales_price, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  productStockList(my_start: any, my_lengths: any, productId: any) {
    this.loadingStock = true;
    this.productsService.getProductsStockById(my_start, my_lengths, productId).subscribe(
      response => {
        if (response.status) {
          this.productsStockList = response.data;
          this.collectionSizeStock = response.recordsTotal;
        }
        this.loadingStock = false;
        this.progressStockTable = false;
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadStockList() {
    this.progressStockTable = true;
    this.productStockList(this.pageStock, this.lengthsStock, this.productId);
  }
  loadStockPage(event: any) {
    this.progressStockTable = true;
    this.pageStock = this.lengthsStock * (event - 1);
    this.productStockList(this.pageStock, this.lengthsStock, this.productId);
  }
  loadStockPageLenghs(event: any) {
    this.progressStockTable = true;
    this.lengthsStock = event.target.value;
    this.productStockList(this.startStock, this.lengthsStock, this.productId);
  }

  deleteStock(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.productsService.deleteStock(id).subscribe(
          response => {
            if (response.status) {
              me.progressStockTable = true;
              me.productStockList(me.startStock, me.lengthsStock, me.productId);
              me.reloadList();
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Product Stock Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }
  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
  getCategoryFilter(categories) {
    this.categoryFilter = categories;
    this.reloadList();
  }


  getProductFilter(searchByName) {
    this.searchByName = searchByName;
    this.reloadList();
  }
}

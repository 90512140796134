<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Transport List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                 <option value="50">50</option>
                                                 <option value="100">100</option>
                                             </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Transport">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">

                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Name</th>
                                            <th> Address</th>
                                            <th> Contact Person</th>
                                            <th> Mobile</th>
                                            <th> GST No</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="transportsList?.length != 0">
                                        <tr *ngFor="let transport of transportsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ transport.name }}</td>
                                            <td>{{ transport.address }}</td>
                                            <td>{{ transport.contact_person }}</td>
                                            <td>{{ transport.mobile }}</td>
                                            <td>{{ transport.gst_no }}</td>
                                            <td>

                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem (click)="toggleSidebar('new-sidebar',transport.id,ndx)"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(transport.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="transportsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>




<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Transport</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">

                                <div class="form-group">
                                    <label for="register-transportName" class="form-label">Transport Name<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="transportName" class="form-control" placeholder="Transport Name" aria-describedby="register-transportName" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.transportName.errors }" />
                                    <div *ngIf="submitted && f.transportName.errors" class="invalid-feedback">
                                        <div *ngIf="f.transportName.errors.required">Transport Name is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-transportAddress" class="form-label">Transport Address<span class="text-danger">*</span> </label>
                                    <textarea rows="2" formControlName="transportAddress" class="form-control" placeholder="Transport Address" aria-describedby="register-transportAddress" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.transportAddress.errors }"></textarea>
                                    <div *ngIf="submitted && f.transportAddress.errors" class="invalid-feedback">
                                        <div *ngIf="f.transportAddress.errors.required">Transport Address is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-contactPerson" class="form-label">Contact Person<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="contactPerson" class="form-control" placeholder="Contact Person" aria-describedby="register-contactPerson" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.contactPerson.errors }" />
                                    <div *ngIf="submitted && f.contactPerson.errors" class="invalid-feedback">
                                        <div *ngIf="f.contactPerson.errors.required">Contact Person is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-transportPhone" class="form-label">Mobile<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="transportPhone" class="form-control" placeholder="Mobile" aria-describedby="register-transportPhone" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.transportPhone.errors }" />
                                    <div *ngIf="submitted && f.transportPhone.errors" class="invalid-feedback">
                                        <div *ngIf="f.transportPhone.errors.required">Mobile is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-transportGst" class="form-label">Transport GST</label>
                                    <input type="text" formControlName="transportGst" class="form-control" placeholder="Transport GST" tabindex="5" />
                                </div>

                                <div class="form-group">
                                    <label for="register-transportRateFormat" class="form-label">Rate Format</label>
                                    <textarea rows="2" formControlName="transportRateFormat" class="form-control" placeholder="Rate Format" tabindex="6"></textarea>
                                </div>

                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                    <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Cancel </span>                                    
                              </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">


                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Sales Executive</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Sales Executive</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">

                                    <div class="form-group col-lg-6">
                                        <label for="register-category_id" class="form-label">Select Category<span class="text-danger">*</span></label>
                                        <ng-select formControlName="category_id" multiple="true" aria-describedby="register-category_id" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.category_id.errors }">
                                            <ng-option [value]="" selected>Select Category</ng-option>
                                            <ng-option *ngFor="let category of categoryDropDown" [value]="category.id">{{category.category_name}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="submitted && f.category_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.category_id.errors.required">Category is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6 ">
                                        <label for="register-name" class="form-label">Name<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="name" class="form-control" placeholder="Name" aria-describedby="register-name" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-6 ">
                                        <label for="register-phone" class="form-label">Phone Number<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number" aria-describedby="register-phone" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                            <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-6 ">
                                        <label for="register-email" class="form-label">Email</label>
                                        <input type="text" formControlName="email" class="form-control" placeholder="Email" aria-describedby="register-email" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.email">Please Enter Valid Email</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-address_one" class="form-label">Address Line 1<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="address_one" class="form-control" placeholder="Address Line 1" aria-describedby="register-address_one" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.address_one.errors }" />
                                        <div *ngIf="submitted && f.address_one.errors" class="invalid-feedback">
                                            <div *ngIf="f.address_one.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-address_two" class="form-label">Address Line 2</label>
                                        <input type="text" formControlName="address_two" class="form-control" placeholder="Address Line 2" aria-describedby="register-address_two" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.address_two.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-address_three" class="form-label">Address Line 3</label>
                                        <input type="text" formControlName="address_three" class="form-control" placeholder="Address Line 3" aria-describedby="register-address_three" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.address_three.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-state_id" class="form-label">Select State<span class="text-danger">*</span></label>
                                        <select formControlName="state_id" class="form-control" (change)="getCity($event)" placeholder="Select State" aria-describedby="register-state_id" tabindex="9" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                            <option value="" selected disabled>Select State</option>
                                            <option *ngFor="let state of stateDropDown" [value]="state.id">{{state.state_name}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.state_id.errors.required">State is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-city_id" class="form-label">Select City<span class="text-danger">*</span></label>
                                        <select formControlName="city_id" class="form-control" placeholder="Select City" aria-describedby="register-city_id" tabindex="10" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                        <option value="" selected >Select City</option>
                                        <option  *ngFor="let city of cityDropDown" [value]="city.id">
                                            {{city.city_name}}</option>
                                    </select>
                                        <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.city_id.errors.required">City is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-pincode" class="form-label">Pincode</label>
                                        <input type="text" formControlName="pincode" class="form-control" placeholder="Pincode" aria-describedby="register-pincode" tabindex="11" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <div class="col-lg-12 p-0">
                                            <label for="register-newPassword" class="form-label">Password<span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                            <input [type]="passwordTextType2 ? 'text' : 'password'" formControlName="newPassword" class="form-control form-control-merge" tabindex="12" [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" placeholder="············" aria-describedby="register-newPassword"
                                                autocomplete="off" />

                                            <div class="input-group-append">
                                                <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType2, 'icon-eye': !passwordTextType2 }" (click)="togglePasswordTextType(2)" ></i>
                                            </span>
                                            </div>
                                            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.newPassword.errors.required">Password is required</div>
                                                <div *ngIf="f.newPassword.touched && f.newPassword.errors?.invalidPassword"> Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">

                                        <div class="col-lg-12 p-0">
                                            <label for="register-confirmPassword" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                            <input [type]="passwordTextType3 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" tabindex="13" [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" placeholder="············" aria-describedby="register-confirmPassword"
                                                autocomplete="off" />

                                            <div class="input-group-append">
                                                <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType3, 'icon-eye': !passwordTextType3 }" (click)="togglePasswordTextType(3)" ></i>
                                            </span>
                                            </div>
                                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                                <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors?.passwordMismatch">Passwords does not match</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-status" class="form-label">Status<span class="text-danger">*</span></label>
                                        <select formControlName="status" class="form-control" aria-describedby="register-status" tabindex="14" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                            <option value="0" selected >Active</option>
                                            <option value="1">Deactivate</option>
                                            <option value="2">Block</option>
                                        </select>
                                    </div>

                                    <div class="col col-sm-12 col-xs-12 col-lg-8">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/employees" class="btn btn-secondary btn-block" tabindex="17" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="isAddMode" tabindex="15" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="!isAddMode" tabindex="16" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})

export class VendorSubCategoryService {

  /**
  *
  * @param {HttpClient} _http
  */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-vendor-sub-categories`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-vendor-sub-categories`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(vendor_category_id:any,sub_category_name: any, description: any, categoryId: any): Observable<any> {
    if (categoryId == 0) {
      return this._http
        .post(`${environment.apiUrl}save-vendor-sub-categories`, { vendor_category_id,sub_category_name, description })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-vendor-sub-categories`, { vendor_category_id,sub_category_name, description, categoryId })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

}

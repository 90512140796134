import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { ProductionGeneralService } from 'app/_services/production-general.service';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { FlatpickrOptions } from 'ng2-flatpickr';


@Component({
  selector: 'app-production-given',
  templateUrl: './production-given.component.html',
  styleUrls: ['./production-given.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductionGivenComponent implements OnInit {

  

  public error = '';
  public newForm: FormGroup;
  public loading = false;
  public operatorsDropDown: any;
  public machinesDropDown: any;
  public colorsDropDown: any;
  public rowMaterialProductsDropDown: any;
  public departmentsDropDown: any;
  public submitted: boolean = false;
  public givenList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;

  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;

  public currentUser: any;
  public currentUserRole = '';

  public basicDateOptions: FlatpickrOptions = {
    altFormat: 'd-m-Y',
    allowInput: true,
    altInput: true,
    minDate: 'today',
  };

  constructor(private _coreConfigService: CoreConfigService, private purchaseOrderService: PurchaseOrderService, private productionGeneralService: ProductionGeneralService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { 

    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;

    console.log(this.currentUserRole);
    
  }

  ngOnInit(): void {

    this.loading = true;
    this.getGivenList(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      department_id: ['', Validators.required],
      operator_id: [''],
      machine_id: [''],
      raw_material_product: [null, [Validators.required]],
      required_qty: [null, [Validators.required]],
      color_id: [null, [Validators.required]],
      require_date: ['', [Validators.required]],
      description: [null]
    });
  }

  get f() {
    return this.newForm.controls;
  }

  getGivenList(my_start: any, my_lengths: any) {
    this.productionGeneralService.givenList(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.givenList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.getGivenList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.getGivenList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.getGivenList(this.start, this.lengths);
  }


  getOperatorsDropDown() {
    this.productionGeneralService.getOperatorsDropDown().subscribe(
      data => {
        if (data.status) {
          this.operatorsDropDown = data.operators;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
  getDepartmentsDropDown() {
    this.productionGeneralService.getDepartmentsDropDown().subscribe(
      data => {
        if (data.status) {
          this.departmentsDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getMachinesDropDown() {
    this.productionGeneralService.getMachinesDropDown().subscribe(
      data => {
        if (data.status) {
          this.machinesDropDown = data.machines;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getcolorsDropDown() {
    this.productionGeneralService.getcolorsDropDown().subscribe(
      data => {
        if (data.status) {
          this.colorsDropDown = data.colors;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getRowMaterialProductsDropDowns() {
    this.purchaseOrderService.getAllRowMaterialProductsDropDown().subscribe(
      data => {
        if (data.status) {
          this.rowMaterialProductsDropDown = data.products;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
  // modal Basic
  modalOpen(modalBasic) {

    this.getOperatorsDropDown();
    this.getDepartmentsDropDown();
    this.getMachinesDropDown();
    this.getRowMaterialProductsDropDowns();
    this.getcolorsDropDown();

    this.modalService.open(modalBasic, {
      size: 'lg'
    });
  }

  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      
      const formdata = new FormData();
      formdata.append('department_id', this.f.department_id.value);
      formdata.append('operator_id', this.f.operator_id.value);
      formdata.append('machine_id', this.f.machine_id.value);
      formdata.append('raw_material_product', this.f.raw_material_product.value);
      formdata.append('required_qty', this.f.required_qty.value);
      formdata.append('color_id', this.f.color_id.value);
      formdata.append('require_date', this.convert(this.f.require_date.value));
      formdata.append('description', this.f.description.value);

      this.productionGeneralService.givenAdd(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.reloadList();
            this.modalService.dismissAll('modalBasic');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.productionGeneralService.givenDelete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getGivenList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Production Given Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
  }

}

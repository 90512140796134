import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TransportsService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-transport-list`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-transport`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(transportData: any,id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}transport-store`, transportData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-transport`, transportData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }
}

<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row ">
        <li class="nav-item">
            <a class="navbar-brand" [routerLink]="['/home']">
                <h2 class="brand-text mb-0"> <img src="assets/images/logo/logo-text.png" alt="brand-logo" /></h2>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

        <!-- Toggle skin -->
        <li class="nav-item d-none d-lg-block">
            <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </li>
        <!--/ Toggle skin -->
    </div>

    <ul class="nav navbar-nav align-items-center ml-auto">
        <!-- ? Language selection | Uncomment if needed-->
        <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
        <!--/ Language selection -->

        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user">
            <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
                <div class="user-nav d-sm-flex d-none">
                    <span class="user-name font-weight-bolder" *ngIf="currentUser && currentUser !== null && currentUser.user !== null"> {{ currentUser.user.name }} </span>
                    <span class="user-name font-weight-bolder" *ngIf="!currentUser"> Guest </span>
                </div>
                <span class="avatar bg-primary " *ngIf="currentUser && currentUser.user.profile_image == null">
                    <div class="avatar-content">{{ currentUser.user.name | initials }}</div>
                </span>
                <span class="avatar " *ngIf="currentUser && currentUser.user.profile_image != null" style="background-color: #fff;">
                    <span class="avatar-content">
                        <img [src]="currentUser.user.profile_image" alt="{{ currentUser.user.name | initials }}" width="32" height="32" />
                    </span>
                </span>
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
                <a *ngIf="currentUser && currentUser.user.role != 'User'" ngbDropdownItem [routerLink]="['/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a>
                <a *ngIf="currentUser && currentUser.user.role != 'User'" ngbDropdownItem [routerLink]="['/setting']"><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a>
                <div class="dropdown-divider"></div>
                <a ngbDropdownItem (click)="logout()" [routerLink]="['/']"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
            </div>
        </li>
        <!--/ User Dropdown -->
        <!-- Help Dropdown 
        <li ngbDropdown class="nav-item dropdown-user" *ngIf="currentUser && currentUser.user.role != 'User'">
            <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-help" ngbDropdownToggle id="navbarHelpDropdown" aria-haspopup="true" aria-expanded="false">

                <div class="user-nav d-sm-flex d-none">
                    <span class="user-name font-weight-bolder"> Help </span>
                </div>
                <span class="avatar bg-info-light">
                    <div class="avatar-content"><i [data-feather]="'info'"></i></div>
                </span>
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarHelpDropdown" class="dropdown-menu dropdown-menu-right">
                <a ngbDropdownItem [routerLink]="['/supplier-guide']"><span [data-feather]="'info'" [class]="'mr-50'"></span> Supplier Guide</a>
            </div>
        </li>
       Help Dropdown -->
    </ul>
</div>
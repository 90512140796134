<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <section id="pricing-plan">
            <!-- title text and switch button -->
            <div class="text-center">
                <h1 class="mt-5">Pricing Plans</h1>
                <p class="mb-2 pb-75">
                    All plans include advanced features. Choose the best plan to fit your needs.
                </p>

            </div>
            <!--/ title text and switch button -->

            <!-- pricing plan cards -->
            <div class="row pricing-card">
                <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
                    <div class="row match-height">
                        <!--  plan -->
                        <div class="col-12 col-md-4" *ngFor="let plan of planList; let ndx = index">
                            <div class="card basic-pricing  text-center">

                                <div class="card-body">
                                    <div class="pricing-badge text-right" *ngIf="plan.hightlight != 0">
                                        <div class="badge badge-pill badge-light-primary">Best Value</div>
                                    </div>
                                    <img src="assets/images/pages/{{ plan.image_path }}" *ngIf="ndx == 0" class="mb-2 mt-5 pt-2" style="width:80px;" alt="svg img" />
                                    <img src="assets/images/pages/{{ plan.image_path }}" *ngIf="ndx == 1" class="mb-1 mt-3" style="width:80px;" alt="svg img" />
                                    <img src="assets/images/pages/{{ plan.image_path }}" *ngIf="ndx == 2" class="mb-2 mt-2" style="width:80px;" alt="svg img" />
                                    <h3>{{ plan.plan_name }}</h3>
                                    <!-- <p class="card-text">Popular</p> -->
                                    <div class="annual-plan">
                                        <div class="plan-price mt-2">
                                            <sup class="font-medium-1 font-weight-bold text-primary">₹ </sup>
                                            <span class="pricing-basic-value font-weight-bolder text-primary">{{ plan.plan_amount  }}</span>
                                            <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ Year</sub>
                                        </div>
                                        <small class="annual-pricing d-none text-muted"></small>
                                    </div>
                                    <button *ngIf="plan.hightlight == 0" class="btn btn-block btn-outline-primary mt-2" rippleEffect>Upgrade Plan</button>
                                    <button *ngIf="plan.hightlight != 0" class="btn btn-block btn-primary mt-2" rippleEffect>Upgrade Plan</button>
                                </div>
                            </div>
                        </div>
                        <!--/  plan -->
                        <div class="col-12">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th colspan="2" width="30%"></th>
                                        <th width="20%">FREE Plan </th>
                                        <th width="25%">Basic Plan : Rs. 3600/- Yearly </th>
                                        <th width="25%">Premium Plan : Rs. 6000/- Yearly </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Customer Order Link</td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Products </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Customers </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Orders </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Delivery Challans </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Orders Report </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Delivery Challans Report </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Quick List Latest Order With Status </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Quicl List Pending Order With due days </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Self-Order Entry </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Order Master Link </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>

                                    <tr>
                                        <td>12</td>
                                        <td>Customer Outstanding </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>

                                    <tr>
                                        <td>13</td>
                                        <td>Product Stock Bulk Upload </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>Product Bulk Upload </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td>Price Per Customer </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>User Wise Orders </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td>Stock Alerts </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>Daily Report on Email </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td>Order SMS Alerts </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td>Customer Excel Upload </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>21</td>
                                        <td>Products Stock Manage </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td>22</td>
                                        <td>Other Product Order </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <th colspan="4"> Cutomer Facilities </th>
                                    </tr>
                                    <tr>
                                        <td colspan="">1</td>
                                        <td colspan="1">Place Order </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td colspan="">2</td>
                                        <td>Order History </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td colspan="">3</td>
                                        <td>Product List </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                    <tr>
                                        <td colspan="">4</td>
                                        <td>Delivery challans </td>
                                        <td class="text-center"><span class="fa fa-close text-danger text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                        <td class="text-center"><span class="fa fa-check text-success text-bold"></span> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>


                </div>
            </div>

            <!--/ pricing plan cards -->

        </section>
    </div>
</div>
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public productsList: any = [];
  public purchaseOrderDetails: any = [];
  public purchaseOrderReceive: any = [];
  public start: any = 0;
  public page: any = 0;
  public currentUser: any;
  public currentUserRole = '';

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private purchaseOrderService: PurchaseOrderService, private modalService: NgbModal) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit(): void {
    this.loading = true;
    this.productList(this.start, this.lengths);
  }

  productList(my_start: any, my_lengths: any) {

    this.purchaseOrderService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.productsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productList(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic, id: any, type: any = 'view') {
    this.loading = false;
    this.purchaseOrderDetails = [];
    this.purchaseOrderReceive = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.purchaseOrderService.getDetails(id).subscribe(
      response => {
        if (response.status) {
          if (type == 'view') {
            this.purchaseOrderDetails = response.purchaseOrder;
          }
          else {
            this.purchaseOrderReceive = response.purchaseOrder;
            this.purchaseOrderReceive.order_details.forEach((meta_value: any, meta_index: any) => {
              this.purchaseOrderReceive.order_details[meta_index].remaining_qty_new = this.purchaseOrderReceive.order_details[meta_index].remaining_qty;
              this.purchaseOrderReceive.order_details[meta_index].receive_qty = 0;
            });
          }
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  countChange(event: any, index: any) {
    this.purchaseOrderReceive.order_details[index].remaining_qty_new = this.purchaseOrderReceive.order_details[index].remaining_qty - event;
    this.purchaseOrderReceive.order_details[index].receive_qty = event;
  }

  onReceiveSave() {

    const formdata = new FormData();
    formdata.append('purchaseOrderReceive', this.purchaseOrderReceive);

    this.purchaseOrderService.saveReceive(this.purchaseOrderReceive).subscribe(
      response => {
        if (response.status) {
          this.reloadList();
          this.modalService.dismissAll();
          this._toastrService.clear();
          this._toastrService.success(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(response.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }

  orderchangeStatus(orderId: any, status: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to change.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think'
    }).then((result) => {
      if (result.value) {
        this.purchaseOrderService.orderchangeStatus(orderId, status).subscribe(
          data => {
            if (data.status) {
              this.reloadList();
              this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
            else {
              this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
            this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Order Status Not Changed',
          'error'
        )
      }
    })
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseOrderService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.productList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Purchase Order Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }
  download(id: any, index: any = 0) {
    this.purchaseOrderService.downloadPdf(id).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'PO.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

}

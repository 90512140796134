<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Customers</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Customers</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label for="register-category_id" class="form-label">Select Category<span class="text-danger">*</span></label>
                                        <ng-select formControlName="category_id" multiple="true" (change)="getEmployeeDropDown($event)" aria-describedby="register-category_id" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.category_id.errors }">
                                            <ng-option [value]="" selected>Select Category</ng-option>
                                            <ng-option *ngFor="let category of categoryDropDown" [value]="category.id">{{category.category_name}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="submitted && f.category_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.category_id.errors.required">Category is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-employee_id" class="form-label">Select Sales Executive<span class="text-danger">*</span></label>
                                        <ng-select formControlName="employee_id" aria-describedby="register-employee_id" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.employee_id.errors }">
                                            <ng-option [value]="" selected>Select Sales Executive</ng-option>
                                            <ng-option *ngFor="let employee of employeeDropDown" [value]="employee.id">{{employee.name}} ({{employee.mobile}})</ng-option>
                                        </ng-select>
                                        <div *ngIf="submitted && f.employee_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.employee_id.errors.required">Sales Executive is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-enterprise_name" class="form-label">Enterprise Name<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="enterprise_name" class="form-control" placeholder="Enterprise Name" aria-describedby="register-enterprise_name" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.enterprise_name.errors }" />
                                        <div *ngIf="submitted && f.enterprise_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.enterprise_name.errors.required">Enterprise Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-owner_name" class="form-label">Owner Name</label>
                                        <input type="text" formControlName="owner_name" class="form-control" placeholder="Owner Name" aria-describedby="register-owner_name" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.owner_name.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-phone" class="form-label">Phone Number<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number" aria-describedby="register-phone" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                            <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-landline" class="form-label">Landline Number</label>
                                        <input type="text" formControlName="landline" class="form-control" placeholder="Landline Number" aria-describedby="register-landline" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.landline.errors }" />
                                        <div *ngIf="submitted && f.landline.errors" class="invalid-feedback">
                                            <div *ngIf="f.landline.errors.pattern">Please Provide Valid Landline No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-alternate_phone" class="form-label">Alternate Phone Number</label>
                                        <input type="text" formControlName="alternate_phone" class="form-control" placeholder="Alternate Phone Number" aria-describedby="register-alternate_phone" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.alternate_phone.errors }" />
                                        <div *ngIf="submitted && f.alternate_phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.alternate_phone.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-email" class="form-label">Email</label>
                                        <input type="text" formControlName="email" class="form-control" placeholder="Email" aria-describedby="register-email" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.email">Please Enter Valid Email</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-address_one" class="form-label">Address Line 1</label>
                                        <input type="text" formControlName="address_one" class="form-control" placeholder="Address Line 1" aria-describedby="register-address_one" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.address_one.errors }" />
                                        <div *ngIf="submitted && f.address_one.errors" class="invalid-feedback">
                                            <div *ngIf="f.address_one.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-address_two" class="form-label">Address Line 2</label>
                                        <input type="text" formControlName="address_two" class="form-control" placeholder="Address Line 2" aria-describedby="register-address_two" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.address_two.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-address_three" class="form-label">Address Line 3</label>
                                        <input type="text" formControlName="address_three" class="form-control" placeholder="Address Line 3" aria-describedby="register-address_three" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.address_three.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-state_id" class="form-label">Select State<span class="text-danger">*</span></label>
                                        <ng-select formControlName="state_id" (change)="getCity($event)" aria-describedby="register-state_id" tabindex="9" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                            <ng-option [value]="" selected>Select State</ng-option>
                                            <ng-option *ngFor="let state of stateDropDown" [value]="state.id">{{state.state_name}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.state_id.errors.required">State is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-city_id" class="form-label">Select City<span class="text-danger">*</span></label>
                                        <ng-select formControlName="city_id" (change)="getCity($event)" aria-describedby="register-city_id" tabindex="10" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                            <ng-option [value]="" selected>Select City</ng-option>
                                            <ng-option *ngFor="let city of cityDropDown" [value]="city.id">{{city.city_name}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.city_id.errors.required">City is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-pincode" class="form-label">Pincode</label>
                                        <input type="text" formControlName="pincode" class="form-control" placeholder="Pincode" aria-describedby="register-pincode" tabindex="11" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-gst_no" class="form-label">GSTIN</label>
                                        <input type="text" formControlName="gst_no" class="form-control text-uppercase" placeholder="GSTIN" aria-describedby="register-gst_no" tabindex="12" [ngClass]="{ 'is-invalid': submitted && f.gst_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-status" class="form-label">Status<span class="text-danger">*</span></label>
                                        <select formControlName="status" class="form-control" (change)="setGuestMode($event)" aria-describedby="register-status" tabindex="13" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                            <option value="0" selected >Active</option>
                                            <option value="1">Deactivate</option>
                                            <option value="2">Block</option>
                                            <option value="3" *ngIf="!isAddMode" disabled>Guest</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-lg-6" *ngIf="!guestMode">
                                        <label for="register-opening-balance" class="form-label">Opening Balance</label>
                                        <input type="number" formControlName="opening_balance" class="form-control" placeholder="Opening Balance" aria-describedby="register-gst_no" tabindex="14" />
                                    </div>
                                    <div class="form-group col-lg-6" *ngIf="!guestMode">
                                        <label for="register-opening-balance-type" class="form-label">Opening Balance Type</label>
                                        <select class="form-control" formControlName="opening_balance_type" aria-describedby="register-opening-balance-type" tabindex="15">
                                            <option value="0" selected disabled>Opening Balance Type</option>
                                            <option value="C">Credit</option>
                                            <option value="D">Debit</option>
                                        </select>
                                    </div>

                                    <div class="col col-sm-12 col-xs-12 col-lg-8">
                                        <ngb-alert [type]=" 'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/dealers" class="btn btn-secondary btn-block" tabindex="17" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="isAddMode" tabindex="16" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="!isAddMode" tabindex="16" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">

            <div class="row">
                <div class="col-12">
                    <form class="add-new" [formGroup]="newForm">

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Stock Transfer Finished Product </h4>
                            </div>
                            <div class="card-body p-0 pb-1">
                                <div class="row m-0">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="from_unit" class="form-label">From Unit<span class="text-danger">*</span></label>
                                            <ng-select formControlName="from_unit" aria-describedby="from_unit" tabindex="1">
                                                <ng-option disabled selected value="1">Main Unit</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="to_unit" class="form-label">To Unit<span class="text-danger">*</span></label>
                                            <ng-select formControlName="to_unit" aria-describedby="to_unit" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.to_unit.errors }">
                                                <ng-option disabled selected value="0">Select To Unit</ng-option>

                                                <ng-option *ngFor="let wareHouse of warehouseDropdownData" [value]="wareHouse.id">{{wareHouse.warehouse_code}} - {{wareHouse.name}}</ng-option>

                                            </ng-select>
                                            <div *ngIf="submitted && f.to_unit.errors" class="invalid-feedback">
                                                <div *ngIf="f.to_unit.errors.required">To Unit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="form-label">Search Order Products<span class="text-danger">*</span></label>
                                            <ng-autocomplete #auto #ngAutoCompleteStatic [data]="allProduct" name="products" [initialValue]="" [minQueryLength]="3" placeholder="Search Product, Min. 3 Characters" [searchKeyword]="keywordProduct" historyIdentifier="allProduct" [historyHeading]="historyHeading"
                                                [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEventProduct($event)' (inputChanged)='onChangeSearchProduct($event)' (inputFocused)='onFocusedProduct($event)' [itemTemplate]="itemTemplateStaticProduct"
                                                [isLoading]="isLoadingProduct" [notFoundTemplate]="notFoundTemplateProduct">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStaticProduct let-items>
                                                <a [innerHTML]="items.full_name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplateProduct let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group  text-center">
                                            <label class="form-label">Available Stock</label>
                                            <input type="text" name="availablestock" formControlName="availablestock" readonly id="availablestock" placeholder="Available Stock" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group text-center">
                                            <label class="form-label text-left">Transfer Stock<span class="text-danger">*</span></label><br/>
                                            <core-touchspin [numberValue]="productQty" size="lg" [stepValue]="5" [minValue]="1" [maxValue]="10000" (onChange)="countChange($event)"></core-touchspin>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group  text-center">
                                            <label></label><br/>
                                            <button type="button" class="btn btn-success" (click)="selectProduct()"> + Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="transfarList?.length != 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-border table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Product Name</th>
                                                <th>Available Stock</th>
                                                <th>Transfer Stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let detail of transfarList; let ndx = index">
                                                <td>{{ ndx+1 }}</td>
                                                <td>{{ detail.full_name}}</td>
                                                <td>{{ detail.current_stock}}</td>
                                                <td>{{ detail.productQty}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 text-right">
                                    <button type="button" class="btn btn-success">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="col p-0">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Price Per Customers Add</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <div class="row m-0">
                            <div class="form-group col-lg-6 ">
                                <div class="row m-0 p-1" *ngIf="dealer_details.length == 0">
                                    <label class="form-label"> Customer Name<span class="text-danger">*</span></label>
                                    <ng-autocomplete #auto #ngAutoCompleteStatic [data]="allDealer" name="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                        [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateStatic let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                                <div class="row m-0 p-1">
                                    <div class="badge badge-pill badge-light-primary mr-1 p-1" *ngFor="let dealer of dealer_details; let ndx = index" style="font-size: 14px;">{{ dealer.name }} <a (click)="removeDealer(ndx)" class="text-danger"><i class="fa fa-close"></i></a></div>
                                </div>
                            </div>
                            <div class="form-group col-6 text-right pt-2 ">
                                <button class="btn btn-primary" (click)="storeDealerPrice()" *ngIf="dealer_details?.length != 0 && productsList?.length != 0">Save</button>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="card" *ngIf="dealer_details?.length != 0">
                    <div class="card-header">
                        <h4 class="cart-title">Products List</h4>
                    </div>
                    <div class="card-body">
                        <div class="overlay" *ngIf="progressMainTable">
                            <div class="spinner-grow" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <table class="table table-striped table-hover valign-middle text-dark">
                            <thead>
                                <tr>
                                    <th class="action-column">#</th>
                                    <th> Product Name</th>
                                    <th> Sale / Purchase Price</th>
                                    <th> New Price </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="productsList?.length != 0">
                                <tr *ngFor="let product of productsList; let ndx = index">
                                    <td>{{ndx+1}}</td>
                                    <td>{{ product.product_code }} {{ product.product_name }}</td>
                                    <td>{{ product.sales_price | number:'1.3-3' }} / {{ product.purchase_price | number:'1.3-3' }}</td>
                                    <td>
                                        <input type="number" class="form-control" (keyup)="setNewPrice($event,ndx)" style="min-width: 100px;" placeholder="New Price" />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="productsList?.length == 0">
                                <tr *ngIf="!loading">
                                    <td colspan="4" class="no-data-available">No Data!</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="no-data-available">
                                        <div class="col" *ngIf="loading">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div class="col p-0" *ngIf="dealer_details?.length != 0 && productsList?.length != 0">
                <div class="card">
                    <div class="card-body">
                        <button class="btn btn-primary pull-right" (click)="storeDealerPrice()">Save</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
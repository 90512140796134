import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { EmployeeService } from 'app/_services/employee.service';
import { DealersService } from 'app/_services/dealers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomvalidationService } from 'app/_services/customvalidation.service';
import { AuthenticationService } from 'app/auth/service';
import { ProductsService } from 'app/_services/products.service';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-employee-add',
  templateUrl: './employee-add.component.html',
  styleUrls: ['./employee-add.component.scss']
})
export class EmployeeAddComponent implements OnInit {
  public userId = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  isAddMode: boolean = true;
  public stateDropDown: any;
  public cityDropDown: any;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  public categoryDropDown: any;

  constructor(private _authenticationService: AuthenticationService, private productsService: ProductsService, private employeeService: EmployeeService, private dealersService: DealersService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder, private customValidator: CustomvalidationService) { }

  ngOnInit(): void {
    this.getCategoryDropDown();
    this.getStateDropdown();
    if (this.route.snapshot.params['id'] !== undefined) {
      this.userId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.userId;
    if (!this.isAddMode) {
      this.edit(this.userId);
      this.newForm = this._formBuilder.group({
        name: ['', Validators.required],
        category_id: ['', Validators.required],
        phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        email: [null, [Validators.email]],
        address_one: [null, [Validators.required]],
        address_two: [null],
        address_three: [null],
        state_id: [null, [Validators.required]],
        city_id: [null, [Validators.required]],
        pincode: [null],
        status: ['0'],
        newPassword: ['', [Validators.compose([this.customValidator.patternValidator()])]],
        confirmPassword: ['']
      }, {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      });
    }
    else {
      this.newForm = this._formBuilder.group({
        name: ['', Validators.required],
        category_id: ['', Validators.required],
        phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        email: [null, [Validators.email]],
        address_one: [null, [Validators.required]],
        address_two: [null],
        address_three: [null],
        state_id: [null, [Validators.required]],
        city_id: [null, [Validators.required]],
        pincode: [null],
        status: ['0'],
        newPassword: ['', [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
        confirmPassword: ['', Validators.required]
      }, {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      });
    }

    this.f.status.setValue('0');
    this.f.state_id.setValue('');
    this.f.city_id.setValue('');
    this.f.category_id.setValue('0');
  }
  get f() {
    return this.newForm.controls;
  }


  edit(id: any) {
    this.employeeService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.userId = response.employee.id;
          this.getCityUsingId(response.employee.state_id);
          this.f.name.setValue(response.employee.name);
          this.f.phone.setValue(response.employee.mobile);
          this.f.email.setValue(response.employee.email);
          this.f.address_one.setValue(response.employee.address_one);
          this.f.address_two.setValue(response.employee.address_two);
          this.f.address_three.setValue(response.employee.address_three);
          this.f.state_id.setValue(response.employee.state_id);
          this.f.city_id.setValue(response.employee.city_id);
          this.f.pincode.setValue(response.employee.pincode);
          this.f.status.setValue(response.employee.status);
          let categories = response.employee.category_id.split(",").map(x => Number.parseInt(x, 10));
          this.f.category_id.setValue(categories);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  getStateDropdown() {
    this.dealersService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCity(event: any) {
    this.dealersService.getCity(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCityUsingId(stateId: any) {
    this.dealersService.getCity(stateId).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      const { name, phone, email, address_one, address_two, address_three, state_id, city_id, pincode, newPassword, status, category_id } = this.newForm.controls;
      this.employeeService.add(name.value, phone.value, email.value, address_one.value, address_two.value, address_three.value, state_id.value, city_id.value, pincode.value, newPassword.value, status.value, category_id.value, this.userId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.userId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/employees']);
          }
          else {

            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.error;
            }
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  /**
   * Toggle password
   */
  togglePasswordTextType(me) {
    if (me == 2) {
      this.passwordTextType2 = !this.passwordTextType2;
    }
    else {
      this.passwordTextType3 = !this.passwordTextType3;
    }
  }
  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Directive, HostListener, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RegisterService } from 'app/_services/register.service';
import { CoreConfigService } from '@core/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  // Public
  public emailVar;
  public coreConfig: any;
  public forgotPasswordForm: FormGroup;
  public registerPasswordForm: FormGroup;
  public submitted = false;
  public submittedPassword = false;
  public otp_screen = false;
  public password_screen = false;
  public loading = false;
  public loadingOtp = false;
  public loadingpassword = false;
  public registerotpForm: FormGroup;
  public passwordTextType: boolean;
  public passwordTextType2: boolean;
  public otpmsg: any = '';
  public errorOtp: any = '';
  public error: any = '';
  public errorPassword: any = '';


  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   *
   */
  constructor(private registerService: RegisterService, private el: ElementRef, private _coreConfigService: CoreConfigService, private _formBuilder: FormBuilder, private _toastrService: ToastrService, private _router: Router) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  get fotp() {
    return this.registerotpForm.controls;
  }

  get fp() {
    return this.registerPasswordForm.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    else {
      this.loading = true;
      this.otp_screen = false;
      this.password_screen = false;

      this.registerService.sentOtp(this.f.email.value).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this.otp_screen = true;
            this.password_screen = false;
            this._toastrService.success(data.message, '', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            if(data.message !== undefined){
              this.errorOtp = data.message;
            }
            if(data.errorMessage !== undefined){
              this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          console.log(err);
        }
      );

    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required]]
    });

    this.registerPasswordForm = this._formBuilder.group({
      password: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    });


    this.registerotpForm = this._formBuilder.group({
      otp_one: ['', [Validators.required]],
      otp_two: ['', [Validators.required]],
      otp_three: ['', [Validators.required]],
      otp_four: ['', [Validators.required]],
      otp_five: ['', [Validators.required]],
      otp_six: ['', [Validators.required]]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  backScreen() {
    this.otp_screen = false;
  }

  nextBox(event: any, next: any) {
    if (event.target.value != "" && event.target.value >= 0 && event.target.value <= 9 && next != "6") {
      const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + next + '"]');
      invalidControl.focus();
    }
  }


  onSubmitotp() {
    this.submitted = true;
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    // stop here if form is invalid
    if (this.registerotpForm.invalid) {

    }
    else {
      this.otpmsg = '';
      this.loadingOtp = true;
      this.otpmsg = this.fotp.otp_one.value + '' + this.fotp.otp_two.value + '' + this.fotp.otp_three.value + '' + this.fotp.otp_four.value + '' + this.fotp.otp_five.value + '' + this.fotp.otp_six.value;
      this.registerService.verifyotp(this.f.email.value, this.otpmsg).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this.otp_screen = false;
            this.password_screen = true;
            //this._toastrService.success('Password Reset Successfully','Congratulations!',{ toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.errorOtp = data.message;
          }
        },
        err => {
          
        }
      );
    }
  }

  /**
  * On Submit
  */
  onSubmitpassword() {
    this.submittedPassword = true;

    // stop here if form is invalid
    if (this.registerPasswordForm.invalid) {
      return;
    }
    else {
      this.loadingpassword = true;

      this.registerService.changePassword(this.f.email.value, this.fp.password.value).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this._router.navigate(['/login']);
            this._toastrService.success('Password Reset Successfully', 'Congratulations!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.errorPassword = data.message;
          }
        },
        err => {
          
        }
      );

    }
  }

  togglePasswordTextType(me) {
    if (me == 0) {
      this.passwordTextType = !this.passwordTextType;
    }
    else {
      this.passwordTextType2 = !this.passwordTextType2;
    }
  }

  resendotp() {
    this.otpmsg = '';
    this.errorOtp = '';
    this.error = '';
    this.errorPassword = '';
    this.registerService.resendotp(this.f.email.value).subscribe(
      data => {
        if (data.status) {
          this._toastrService.success(data.message, '', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        console.log(err);
        this._toastrService.error(err, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );

  }
}

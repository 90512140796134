import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length, is_set_price,searchByName=''): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-vendor`, { start, length, is_set_price,searchByName })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-vendor`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  deleteVendorRawMaterialProduct(id,vendor_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-vendors-raw-material-product`, { id,vendor_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-vendor`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  add(name: any, mobile: any, email: any, address: any, contact_person: any, gst_no: any,rating:any,categoryId:any,mobile_two:any,mobile_three:any,sub_category_id:any,paymentCondition:any,id: any,rawMaterialProducts:any = []): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-vendor`, { name, mobile, email, address, contact_person, gst_no,rating,categoryId,mobile_two,mobile_three,sub_category_id,paymentCondition })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-vendor`, { name, mobile, email, address, contact_person, gst_no,rating,categoryId,mobile_two,mobile_three,sub_category_id,rawMaterialProducts,paymentCondition, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  getvendorCategoriesDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-vendor-categories-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getvendorSubCategoriesDropDown(category_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-vendor-sub-categories-dropdown`,{category_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}

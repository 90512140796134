<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);">
            <h2 class="brand-text text-primary ml-1"> <img src="assets/images/logo/logo-text.png" alt="brand-logo" /></h2>
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-6 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/login.svg'
              : 'assets/images/pages/login.svg'
          " alt="Login V2" />
            </div>
        </div>
        <!-- /Left Text-->
        <!-- Login-->
        <div class="d-flex col-lg-6 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title font-weight-bold mb-1">Welcome to TechFirst!</h2>
                <p class="card-text mb-2">Please sign-in to your account and start manage your orders</p>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error }}</p>
                    </div>
                </ngb-alert>

                <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="form-label" for="login-email">Email / Mobile</label>
                        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="admin@demo.com" aria-describedby="login-email" autofocus="" tabindex="1" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email / Mobile is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label class="form-label" for="login-password">Password</label>
                            <a routerLink="/forgot-password"><small>Forgot Password?</small></a>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" placeholder="············" aria-describedby="login-password" tabindex="2"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType }" (click)="togglePasswordTextType()" ></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.password.errors }">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
                            <label class="custom-control-label" for="remember-me"> Remember Me</label>
                        </div>
                    </div>
                    <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
                    </button>
                </form>
            </div>
        </div>
        <!-- /Login-->
    </div>
</div>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Raw Material Stock Report For Order</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-12">
                                    <div class="form-group">
                                        <label class="form-label">Search Order Products<span class="text-danger">*</span></label>
                                        <ng-autocomplete #auto #ngAutoCompleteStatic [data]="allProduct" name="products" [initialValue]="" [minQueryLength]="3" placeholder="Search Product, Min. 3 Characters" [searchKeyword]="keywordProduct" historyIdentifier="allProduct" [historyHeading]="historyHeading"
                                            [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEventProduct($event)' (inputChanged)='onChangeSearchProduct($event)' (inputFocused)='onFocusedProduct($event)' [itemTemplate]="itemTemplateStaticProduct"
                                            [isLoading]="isLoadingProduct" [notFoundTemplate]="notFoundTemplateProduct">
                                        </ng-autocomplete>
                                        <ng-template #itemTemplateStaticProduct let-items>
                                            <a [innerHTML]="items.full_name"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplateProduct let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-md-2 col-6 ">
                                    <div class="form-group  text-center">
                                        <label class="form-label">Order Qty.<span class="text-danger">*</span></label><br/>
                                        <core-touchspin [numberValue]="0" size="lg" [stepValue]="5" [minValue]="1" [maxValue]="10000" (onChange)="countChange($event)"></core-touchspin>
                                    </div>
                                </div>
                                <div class="col-md-2 col-3 ">
                                    <div class="form-group text-center ">
                                        <label></label><br/>
                                        <button class="btn btn-primary " type="button " [disabled]="loading" (click)="getDetails()">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 "></span>Go
                                        </button>

                                    </div>
                                </div>
                                <div class="col-md-2 col-3 d-flex justify-content-start justify-content-md-end" *ngIf="stockDetails?.length != 0">
                                    <div class="form-group text-end ">
                                        <label></label><br/>
                                        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="stockDetails?.length != 0">

                <div class="col-12" id="print-section">
                    <div class="card card-mb-1 ">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col">
                                    <table class="d-none" style="width: 100%;">
                                        <tr>
                                            <td style="text-align: center;margin-top: 10px;">
                                                <h3 style="margin-bottom: 2px;">Raw Material Stock Report For Order</h3>
                                                <span style="font-size: 12px;color: #5a5a5a;">Report Date : {{ stockDetails.report_date }}</span>
                                            </td>
                                        </tr>
                                    </table>



                                    <table class="table table-bordered table-hover table-striped " style="width:100%;border:1px #8a8a8a solid;border-collapse: collapse;">
                                        <tr>
                                            <td class="item-img col-md-1 col-4">
                                                <img *ngIf="stockDetails.product_image_thum == ''" src="assets/images/no-img.png" height="100" width="100" alt="IMG" class="rounded" />
                                                <img *ngIf="stockDetails.product_image_thum != ''" [src]="stockDetails.product_image_thum" height="100" width="100" alt="IMG" class="rounded">
                                            </td>
                                            <td class="col-md-7 col-8" style="width:90%">
                                                <div class="item-name ">
                                                    <h5 class="mb-0" style="margin: 0px;">
                                                        {{ stockDetails.product_code }} {{ stockDetails.product_name }}
                                                    </h5>
                                                    <span class="item-company "> {{ stockDetails.size_title }}  {{ stockDetails.color_title }}</span>
                                                    <h6 style="margin: 0px;"><b>Order Qty : </b> {{ orderQty }}</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row ">

                                <div class="col-12 table-responsive ">
                                    <table class="table table-bordered table-hover table-striped " style="width:100%;border-collapse: collapse;border: 0.5px #8a8a8a solid;margin-top: 15px;">
                                        <thead>
                                            <tr style="background-color: #eaeaea;">
                                                <td style="padding:5px;"><b>Raw Material Product</b></td>
                                                <td style="padding:5px;"><b>Use Qty</b></td>
                                                <td style="padding:5px;"><b>Current Stock</b></td>
                                                <td style="padding:5px;"><b>Required Qty</b></td>
                                                <td style="padding:5px;"><b>Need</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let rmsProduct of stockDetails.comboProducts; let ndx=i ndex ">
                                                <td style="padding:5px;">{{rmsProduct.product_name}}</td>
                                                <td style="padding:5px;">{{rmsProduct.useQuantity}}</td>
                                                <td style="padding:5px;">{{rmsProduct.current_stock}}</td>
                                                <td style="padding:5px;">{{rmsProduct.require}}</td>
                                                <td style="padding:5px;" *ngIf="rmsProduct.need > 0" class="text-danger">{{rmsProduct.need}}</td>
                                                <td style="padding:5px;" *ngIf="rmsProduct.need == 0">N/A</td>
                                            </tr>

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row match-height">
                <div class="col-xl-9 col-lg-8 col-md-7">
                    <div class="card">
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="card-header col-12">
                                                <h6 class="card-title">SMS Settings</h6>
                                            </div>
                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">SMS Alert</p>
                                                    <input type="checkbox" checked class="custom-control-input" formControlName="sms_alert" value="1" id="sms_alert" />
                                                    <label class="custom-control-label" for="sms_alert"></label>
                                                </div>
                                            </div>
                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">SMS Alert Customer</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="is_active_sms_customer" value="1" id="is_active_sms_customer" />
                                                    <label class="custom-control-label" for="is_active_sms_customer"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="card-header col-12">
                                                <h6 class="card-title">Price & GST Display Settings</h6>
                                            </div>
                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">GST Display In Print</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="gst_display" value="1" id="gst_display" />
                                                    <label class="custom-control-label" for="gst_display"></label>
                                                </div>
                                            </div>
                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">Price Display In Print</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="price_display" value="1" id="price_display" />
                                                    <label class="custom-control-label" for="price_display"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="card-header col-12">
                                                <h6 class="card-title">Stock Settings</h6>
                                            </div>

                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">Stock Alert</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="stock_alert" value="1" id="stock_alert" />
                                                    <label class="custom-control-label" for="stock_alert"></label>
                                                </div>
                                            </div>

                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">Stock Deduct</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="is_deduct_stock" value="1" (change)="stockCheckbox(1)" id="is_deduct_stock" />
                                                    <label class="custom-control-label" for="is_deduct_stock"></label>
                                                </div>
                                            </div>

                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">Stock Deduct Order Time</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="is_deduct_stock_order" (change)="stockCheckbox(2)" value="1" id="is_deduct_stock_order" />
                                                    <label class="custom-control-label" for="is_deduct_stock_order"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-none">
                                            <div class="card-header col-12">
                                                <h6 class="card-title">Order Master Link Settings</h6>
                                            </div>
                                            <div class="form-group col-lg-4">
                                                <div class="custom-control custom-control-primary custom-switch text-center">
                                                    <p class="form-label">Take Order</p>
                                                    <input type="checkbox" class="custom-control-input" formControlName="order_master_link" value="1" id="order_master_link" />
                                                    <label class="custom-control-label" for="order_master_link"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 col-md-9">
                                        <ngb-alert [type]=" 'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-5">
                    <div class="card">
                        <div class="card-body pb-1">
                            <h5>Instructions</h5>
                            <ul class="pt-0 pl-1 mt-0 m-0 text-justify">
                                <li><b>SMS Alert</b> Is On Then, You Will Able To Receive SMS Alert Of Orders.</li>
                                <li><b>SMS Alert</b> Customer Is On Then, Customers Will Receive SMS Of Delivery Challan.</li>
                                <li><b>GST Display</b> In Print Is On Then, GST Number Will Display In Print And PDF.</li>
                                <li><b>Price Display</b> In Print Is On Then, Price Will Shown In Print And PDF.</li>
                                <li><b>Stock Alert</b> Is On Then, You Will Able To Receive Stock Alerts At Dashboard.</li>
                                <li><b>Stock Deduct</b> Is On Then, Stock Will Deduct After Creating Delivery Challan.</li>
                                <li><b>Stock Deduct Order Time</b> Order Time Is On Then, Stock Will Deduct After Placing Order.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
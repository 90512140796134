import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OperatorsService } from 'app/_services/operators.service';

@Component({
  selector: 'app-operator-master',
  templateUrl: './operator-master.component.html',
  styleUrls: ['./operator-master.component.scss']
})
export class OperatorMasterComponent implements OnInit {

  constructor(private _router: Router, private operatorsService: OperatorsService, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  public operatorsList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;
  public submitted: boolean = false;
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public operatorId = 0;
  public error = '';
  public newForm: FormGroup;
  public loading = false;
  public selected_photo_AvatarImage: string = 'assets/images/user.jpg';
  public operator_selected_photo: any = '';

  ngOnInit(): void {

    this.loading = true;
    this.operatorsLists(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      name: ['', Validators.required],
      phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
      phone_tow: [null, [Validators.pattern('^[0-9]{10}')]],
      aadhar_number: [null, [Validators.pattern('^[0-9]{12}')]],
      address: [null]
    });
  }

  operatorsLists(my_start: any, my_lengths: any) {
    this.operatorsService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.operatorsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  // modal Basic
  modalOpen(modalBasic) {
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
  }

  uploadFile(event: any) {
    
    this.operator_selected_photo = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.selected_photo_AvatarImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.operatorsService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.operatorsLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Operator Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.operatorsLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.operatorsLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.operatorsLists(this.start, this.lengths);
  }
  get f() {
    return this.newForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;

      const formdata = new FormData();
      formdata.append('operator_photo', this.operator_selected_photo);
      formdata.append('operator_name', this.f.name.value);
      formdata.append('phone', this.f.phone.value);
      formdata.append('phone_tow', this.f.phone_tow.value);
      formdata.append('aadhar_number', this.f.aadhar_number.value);
      formdata.append('address', this.f.address.value);

      this.operatorsService.add(formdata, this.operatorId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.operatorId = 0;
            this.submitted = false;
            this.newForm.reset();
            this.modalService.dismissAll('modalBasic');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this.error = data.error;
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
}

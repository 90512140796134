import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CategoriesService } from 'app/_services/categories.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CategoryComponent } from 'app/component/category/category.component';

@Component({
  selector: 'app-new-sidebar',
  templateUrl: './new-sidebar.component.html'
})
export class NewSidebarComponent implements OnInit {
  
  public categoryId = 0; 
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(private categoryComponent: CategoryComponent, private _coreSidebarService: CoreSidebarService, private _toastrService: ToastrService, private categoryService: CategoriesService, private _formBuilder: FormBuilder) { }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
   * Submit
   *
   * @param form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.error = '';
      this.loading = true;
      const { categoryName } = this.newForm.controls;

      this.categoryService.add(categoryName.value, this.categoryId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.categoryId = 0
            this.submitted = false;
            this.newForm.reset();
            this.toggleSidebar('new-sidebar');
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.categoryComponent.reloadList();
          }
          else {
            
            if(data.error.category_name)
            {
              this.error = data.error.category_name[0];
            }
            else {
              this.error = data.error;
            }
           
          }

        });
    }
  }

  ngOnInit(): void {

    this.newForm = this._formBuilder.group({
      categoryName: ['', [Validators.required]]
    });

  }
  get f() {
    return this.newForm.controls;
  }
}

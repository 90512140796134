<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Operator List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pb-1 pb-md-0 w-100">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="modalOpen(modalBasic)">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Photo</th>
                                            <th> Operator Name</th>
                                            <th> Phone No 1</th>
                                            <th> Aadhar </th>
                                            <th> Address </th>
                                            <th> Join Date</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="operatorsList?.length != 0">
                                        <tr *ngFor="let operator of operatorsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>
                                                <img [src]="operator.operator_photo_thum" height="40" width="40" alt="IMG" class="rounded mr-75">
                                            </td>
                                            <td>{{ operator.operator_name }}</td>
                                            <td>{{ operator.phone }} / {{ operator.phone_tow }}</td>
                                            <td>{{ operator.aadhar_number }} </td>
                                            <td>{{ operator.address }}</td>
                                            <td>{{ operator.joindate }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <!-- <a ngbDropdownItem routerLink="/employees/edit/{{ operator.id }}"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a> -->
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(operator.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="operatorsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Operator</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-12 pt-1 overflow-auto">
                <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">


                        <div class="form-group col-lg-12 ">
                            <label for="register-name" class="form-label">Full Name<span class="text-danger">*</span></label>
                            <input type="text" formControlName="name" class="form-control" placeholder="Full Name" aria-describedby="register-name" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Full Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 ">
                            <label for="register-phone" class="form-label">Phone Number 1<span class="text-danger">*</span></label>
                            <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number 1" aria-describedby="register-phone" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 ">
                            <label for="register-phone_tow" class="form-label">Phone Number 2</label>
                            <input type="text" formControlName="phone_tow" class="form-control" placeholder="Phone Number 2" aria-describedby="register-phone_tow" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.phone_tow.errors }" />

                        </div>

                        <div class="form-group col-lg-9">

                            <div class="form-group">
                                <label for="register-aadhar_number" class="form-label">Aadhar Number</label>
                                <input type="text" formControlName="aadhar_number" class="form-control" placeholder="Aadhar Number" aria-describedby="register-aadhar_number" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.aadhar_number.errors }" />
                            </div>
                            <div class="form-group">
                                <label for="register-address" class="form-label">Address</label>
                                <textarea rows="5" formControlName="address" class="form-control" placeholder="Address" aria-describedby="register-address" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label for="register-photo" class="form-label">Photo</label>

                            <div class="card card-upload">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <img [src]="selected_photo_AvatarImage" alt="Upload" class="img-fluid">
                                        </div>
                                        <div class="col-12">
                                            <input class="form-control" type="file" id="photo" (change)="uploadFile($event)" name="photo" formControlName="photo" tabindex="6">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col col-sm-12 col-xs-12 col-lg-12">
                            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                <div class="alert-body">
                                    <p>{{ error }}</p>
                                </div>
                            </ngb-alert>
                        </div>

                        <div class="col col-sm-6 col-xs-6 col-lg-12 pt-1 text-right">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                Close
                            </button>

                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="7" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>

                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>

</ng-template>
<!-- / Modal -->
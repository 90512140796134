import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { ProductionGeneralService } from 'app/_services/production-general.service';

@Component({
  selector: 'app-production-submit',
  templateUrl: './production-submit.component.html',
  styleUrls: ['./production-submit.component.scss']
})
export class ProductionSubmitComponent implements OnInit {

  public error = '';
  public newForm: FormGroup;
  public loading = false;
  public submitted = false;
  public progressGivenBatchsDropDown = false;
  public progressOperatorsDropDown = false;
  public progressMachinesDropDown = false;
  public selected_photo_AvatarImage: string = 'assets/images/bar_chart.png';
  public givenBatchsDropDown: any = [];
  public operatorsDropDown: any = [];
  public machinesDropDown: any = [];
  public remarksDropDown: any = [];
  public departmentsDropDown: any = [];
  public raw_material_product_id: any = 0;
  public chart_selected_photo: any = '';

  public submitList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;
  public saveTransfer: boolean = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;

  public currentUser: any;
  public currentUserRole = '';

  constructor(private _coreConfigService: CoreConfigService, private productionGeneralService: ProductionGeneralService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) {

    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;

  }



  ngOnInit(): void {

    this.loading = true;
    this.getSubmitList(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      batch_no: ['', Validators.required],
      shift: [''],
      operator_name: [null],
      machine_name: [null],
      raw_material_product: [null, [Validators.required]],
      required_qty: [null, [Validators.required]],
      ok_qty: [null, [Validators.required]],
      reject_qty: [0],
      remarks: [null],
      extra_remarks: [null],
      photo: [null],
      transfer_department_id: [null],
    });
  }

  get f() {
    return this.newForm.controls;
  }
  getSubmitList(my_start: any, my_lengths: any) {
    this.productionGeneralService.submitList(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.submitList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.getSubmitList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.getSubmitList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.getSubmitList(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic) {
    this.saveTransfer = false;
    this.f.reject_qty.setValue(0);
    this.progressGivenBatchsDropDown = true;
    this.progressOperatorsDropDown = true;
    this.progressMachinesDropDown = true;
    this.getGivenBatchsDropDown();
    this.getOperatorsDropDown();
    this.getMachinesDropDown();
    this.getProductionSubmitRemark();
    this.getDepartmentsDropDown();
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
  }

  uploadFile(event: any) {
    this.chart_selected_photo = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.selected_photo_AvatarImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  getGivenBatchsDropDown() {
    this.productionGeneralService.getGivenBatchsDropDown().subscribe(
      data => {
        this.progressGivenBatchsDropDown = false;
        if (data.status) {
          this.givenBatchsDropDown = data.batches;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getDepartmentsDropDown() {
    
    this.productionGeneralService.getDepartmentsTransferDropDown().subscribe(
      data => {
        if (data.status) {
          this.departmentsDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getOperatorsDropDown() {
    this.productionGeneralService.getOperatorsDropDown().subscribe(
      data => {
        this.progressOperatorsDropDown = false;
        if (data.status) {
          this.operatorsDropDown = data.operators;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getMachinesDropDown() {
    this.productionGeneralService.getMachinesDropDown().subscribe(
      data => {
        this.progressMachinesDropDown = false;
        if (data.status) {
          this.machinesDropDown = data.machines;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
  getProductionSubmitRemark() {
    this.productionGeneralService.productionSubmitRemark().subscribe(
      data => {
        this.progressMachinesDropDown = false;
        if (data.status) {
          this.remarksDropDown = data.remarks;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  selectGivenBatch(givenId) {
    this.givenBatchsDropDown.forEach((value: any, index: any) => {
      if (value.id == givenId) {
        this.f.operator_name.setValue(value.operator_id);
        this.f.machine_name.setValue(value.machine_id);
        this.f.raw_material_product.setValue(value.product_name);
        this.f.required_qty.setValue(value.required_qty);
        this.raw_material_product_id = value.raw_material_product_id;
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      const formdata = new FormData();
      formdata.append('chart_photo', this.chart_selected_photo);
      formdata.append('batch_no', this.f.batch_no.value);
      formdata.append('shift', this.f.shift.value);
      formdata.append('operator_name', (this.f.operator_name.value != null) ? this.f.operator_name.value : 0);
      formdata.append('machine_name', (this.f.machine_name.value != null) ? this.f.machine_name.value : 0);
      formdata.append('raw_material_product', this.raw_material_product_id);
      formdata.append('required_qty', this.f.required_qty.value);
      formdata.append('ok_qty', this.f.ok_qty.value);
      formdata.append('reject_qty', this.f.reject_qty.value);
      formdata.append('remarks', this.f.remarks.value);
      formdata.append('extra_remarks', this.f.extra_remarks.value);
      formdata.append('extra_remarks', this.f.extra_remarks.value);

      if (this.saveTransfer) {
        formdata.append('transfer_department_id', this.f.transfer_department_id.value);
      }

      this.productionGeneralService.submitAdd(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.reloadList();
            this.modalService.dismissAll('modalBasic');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  transfer() {
    this.saveTransfer = true;
  }
}

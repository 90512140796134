<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Edit Vendor</h4>
                        </div>
                        <div class="card-body pb-1">

                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label for="register-vendorName" class="form-label">Vendor Name<span class="text-danger">*</span> </label>
                                        <input type="text" formControlName="vendorName" class="form-control" placeholder="Vendor Name" aria-describedby="register-Vendor" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.vendorName.errors }" />
                                        <div *ngIf="submitted && f.vendorName.errors" class="invalid-feedback">
                                            <div *ngIf="f.vendorName.errors.required">Vendor Name is required</div>
                                        </div>
                                    </div>


                                    <div class="form-group col-lg-6">
                                        <label for="register-vendorAddress" class="form-label">Address<span class="text-danger">*</span> </label>
                                        <textarea rows="3" formControlName="vendorAddress" class="form-control" placeholder="Address" aria-describedby="register-vendorAddress" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.vendorAddress.errors }"></textarea>
                                        <div *ngIf="submitted && f.vendorAddress.errors" class="invalid-feedback">
                                            <div *ngIf="f.vendorAddress.errors.required">Address is required</div>
                                        </div>
                                    </div>


                                    <div class="form-group col-lg-4">
                                        <label for="register-vendorMobile" class="form-label">Mobile 1<span class="text-danger">*</span> </label>
                                        <input type="text" formControlName="vendorMobile" class="form-control" placeholder="Mobile" aria-describedby="register-vendorMobile" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.vendorMobile.errors }" />
                                        <div *ngIf="submitted && f.vendorMobile.errors" class="invalid-feedback">
                                            <div *ngIf="f.vendorMobile.errors.required">Mobile is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-4">
                                        <label for="register-vendorMobileTwo" class="form-label">Mobile 2</label>
                                        <input type="text" formControlName="vendorMobileTwo" class="form-control" placeholder="Alternet Mobile" aria-describedby="register-vendorMobileTwo" tabindex="4" />
                                    </div>

                                    <div class="form-group col-lg-4">
                                        <label for="register-vendorMobileThree" class="form-label">Mobile 3</label>
                                        <input type="text" formControlName="vendorMobileThree" class="form-control" placeholder="Alternet Mobile 2" aria-describedby="register-vendorMobileThree" tabindex="5" />
                                    </div>


                                    <div class="form-group col-lg-6">
                                        <label for="register-vendorEmail" class="form-label">Email</label>
                                        <input type="text" formControlName="vendorEmail" class="form-control" placeholder="Email" aria-describedby="register-vendorEmail" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.vendorEmail.errors }" />
                                        <div *ngIf="submitted && f.vendorEmail.errors" class="invalid-feedback">
                                            <div *ngIf="f.vendorEmail.errors.required">Email is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-6">
                                        <label for="register-vendorGSTno" class="form-label">GST</label>
                                        <input type="text" formControlName="vendorGSTno" class="form-control" placeholder="GST Details" aria-describedby="register-vendorGSTno" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.vendorGSTno.errors }" />
                                        <div *ngIf="submitted && f.vendorGSTno.errors" class="invalid-feedback">
                                            <div *ngIf="f.vendorGSTno.errors.required">GST No. is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-12">
                                        <label for="register-vendorPaymentCondition" class="form-label">Payment Condition</label>
                                        <textarea rows="3" formControlName="vendorPaymentCondition" class="form-control" placeholder="Payment Condition" aria-describedby="register-vendorPaymentCondition" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.vendorPaymentCondition.errors }"></textarea>
                                    </div>
                                </div>
                            </form>

                            <div class="row">
                                <div class="col-lg-12">
                                    <h4>Raw material supply details</h4>
                                </div>
                            </div>


                            <div class="row" *ngFor="let product of purchaseProducts; let i = index;">
                                <div class="form-group col-lg-8">
                                    <label>Raw Material Product</label>
                                    <ng-select name="raw_material_product_{{product.id}}" [(ngModel)]="product.raw_material_product">
                                        <ng-option disabled selected value="0">Select Row Material Product</ng-option>
                                        <ng-option *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown" [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-lg-3">
                                    <label>Price<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="product.price" name="price_{{product.id}}" placeholder="Price" />
                                </div>

                                <div class="form-group  col-lg-1">
                                    <br/>
                                    <button type="button" class="btn btn-danger" (click)="remove(i,product.raw_material_product)">
                                                <i  data-feather="trash"></i>
                                         </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-end">
                                    <button type="button" class="btn btn-outline-success mb-2 float-right" (click)="addMore()"><i  data-feather="plus"></i> Add More</button>
                                </div>

                                <div class="clearfix"></div>
                                <div class="col col-sm-12 col-xs-12 col-lg-8">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                    <button [disabled]="loading" type="button" routerLink="/vendors" class="btn btn-secondary btn-block" tabindex="19" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                    </button>
                                </div>
                                <div class="col col-sm-6 col-xs-6 offset-lg-8 col-lg-2 pt-1">
                                    <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="18" (click)="onSubmit()" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
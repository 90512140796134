<div class="content-wrapper container-xxl p-0">
    <form name="form" [formGroup]="newForm" autocomplete="off">
        <div class="content-body">
            <section id="home-page">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Orders Add</h4>
                                <a routerLink="/orders"><span class="btn btn-outline-dark btn-sm pull-right" rippleEffect><span [data-feather]="'chevron-left'"></span> Back</span></a>
                            </div>
                            <div class="card-body p-0 pb-1">
                                <div class="row m-0">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                            <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" name="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                                [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                                [notFoundTemplate]="notFoundTemplate" (inputCleared)="closedStatic()">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStatic let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div class="form-group">
                                            <label for="register-name" class="form-label">P.O. Number<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="P.O. Number" formControlName="poNumber" id="poNumber" name="poNumber" />
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Search Products<span class="text-danger">*</span></label>
                                            <ng-autocomplete #auto #ngAutoCompleteStatic [disabled]="dealerSelected" [data]="allProduct" name="products" [initialValue]="" [minQueryLength]="3" placeholder="Search Product, Min. 3 Characters" [searchKeyword]="keywordProduct" historyIdentifier="allProduct"
                                                [historyHeading]="historyHeading" [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEventProduct($event)' (inputChanged)='onChangeSearchProduct($event)' (inputFocused)='onFocusedProduct($event)'
                                                [itemTemplate]="itemTemplateStaticProduct" [isLoading]="isLoadingProduct" [notFoundTemplate]="notFoundTemplateProduct">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStaticProduct let-items>
                                                <a [innerHTML]="items.full_name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplateProduct let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row  pt-2 text-right ">
                                            <div class="col-6 col-md-12 form-group text-right">
                                                <span *ngIf="total_qty > 0"> Total Qty : {{total_qty}}</span>
                                            </div>
                                            <div class="col-6 col-md-12 form-group text-right">
                                                <button class="btn btn-primary" [disabled]="loading" (click)="storeDealerOrder()" *ngIf="total_qty > 0">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>  Submit
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="ecommerce-application" *ngIf="order_details?.length != 0">
                <section class="row">
                    <div class="col-md-6 col-xs-12" *ngFor="let detail of order_details; let ndx = index">
                        <div class="card">
                            <div class="card-body p-1">
                                <div class="row">
                                    <div class="col-md-3 col-3">
                                        <img class="img-fluid card-img-top m-auto" *ngIf="detail.product_photo != ''" src="{{detail.product_photo}}" alt="img-placeholder" style="max-width: 100px;" />
                                        <img class="img-fluid card-img-top m-auto" *ngIf="detail.product_photo == ''" src="assets/images/no-img.png" alt="img-placeholder" style="max-width: 100px;" />
                                    </div>
                                    <div class="col-md-6 col-9">
                                        <span *ngIf="detail.category_name != ''">{{detail.category_name}}<br/></span>
                                        <span>{{detail.product_code}} {{detail.product_name}}</span><br/>
                                        <span *ngIf=" detail.size_title != '' || detail.color_title != '' ">
                                        <span *ngIf="detail.size_title != '' ">Size : {{detail.size_title}}</span>
                                        <span *ngIf="detail.color_title != '' ">, &nbsp;Color : {{detail.color_title}}</span>
                                        <br/>
                                        </span>

                                        <span *ngIf="detail.packing_qty> 0 || detail.master_packing_qty > 0">
                                        <span *ngIf="detail.packing_qty > 0">Packing Qty : {{detail.packing_qty}}</span>
                                        <span *ngIf="detail.master_packing_qty > 0">, &nbsp; Master Packing Qty : {{detail.master_packing_qty}}</span>
                                        <br/>
                                        </span>

                                        <span *ngIf="detail.unit != '' && detail.unit != null && detail.unit != 'null'">Unit : {{detail.unit}}</span>
                                        <span *ngIf="detail.sales_price != '' && detail.sales_price != 'null' && detail.sales_price > 0">&nbsp; &nbsp; 
                                        <b *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount > 0"><del class="text-danger">₹ {{detail.sales_price.toFixed(2) }}</del></b>
                                        <b *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount == 0">₹ {{detail.sales_price.toFixed(2) }}</b>
                                    </span>
                                        <span *ngIf="detail.discount != '' && detail.discount != 'null' && detail.discount > 0">&nbsp; &nbsp; <b>₹ {{detail.discount.toFixed(2) }}</b></span>
                                        <br/>

                                        <div class="badge badge-light-danger d-none d-sm-inline-block" (click)="productDelete(detail.id)" rippleEffect><i class="fa mr-25 fa-trash"></i> Remove</div>
                                    </div>
                                    <div class="col-md-3 col-12 text-right d-none d-sm-inline-block">
                                        <core-touchspin *ngIf="detail.min_order_qty > 0" [numberValue]="detail.min_order_qty" size="sm" [stepValue]="detail.min_order_qty" [minValue]="detail.min_order_qty" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                        <core-touchspin *ngIf="detail.min_order_qty == 0" [numberValue]="1" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-6 text-center pt-50 d-sm-none d-inline-block">
                                        <div class="badge badge-light-danger" (click)="productDelete(detail.id)" rippleEffect><i class="fa mr-25 fa-trash"></i> Remove</div>
                                    </div>
                                    <div class="col-6 text-right  d-sm-none d-inline-block">
                                        <core-touchspin *ngIf="detail.min_order_qty > 0" [numberValue]="detail.min_order_qty" size="sm" [stepValue]="detail.min_order_qty" [minValue]="detail.min_order_qty" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                        <core-touchspin *ngIf="detail.min_order_qty == 0" [numberValue]="1" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="1000" (onChange)="countChange($event,detail.id)"></core-touchspin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="form-group">
                                        <textarea formControlName="remarks" class="form-control" rows="4" id="remarks" placeholder="Remark If Any"></textarea>
                                    </div>
                                </div>
                                <div class="col-6  col-md-6 pt-2 text-right ">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group  pt-2  text-right">
                                                <span *ngIf="total_qty > 0"> Total Qty : {{total_qty}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button class="btn btn-danger mr-1" (click)="removeAll()" *ngIf="total_qty > 0">Clear All</button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button class="btn btn-primary" [disabled]="loading" (click)="storeDealerOrder()" *ngIf="total_qty > 0">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    </form>
</div>
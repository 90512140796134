<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Raw Material Stock Give List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                 <option value="50">50</option>
                                                 <option value="100">100</option>
                                             </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pb-1 pb-md-0 w-100">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="modalOpen(modalBasic)">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Given Date</th>
                                            <th>Given Batch</th>
                                            <th>Description</th>
                                            <th>View</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="givenList?.length != 0">
                                        <tr *ngFor="let given of givenList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ given.given_at }}</td>
                                            <td>{{ given.given_batch }}</td>
                                            <td>{{ given.extra_remarks }}</td>
                                            <td>
                                                <button type="button" (click)="viewDetails(modalBasicDetails,given.id)" class="btn btn-xs btn-info">
                                                    <i data-feather="eye"></i>
                                                  </button>
                                            </td>

                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(given.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="givenList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Stock Given</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-12 pt-1 overflow-auto">


                <div class="row" *ngFor="let product of givenProducts; let i = index;">
                    <div class="form-group col-lg-8">
                        <label>Raw Material Product</label>
                        <ng-select name="raw_material_product_{{product.id}}" [(ngModel)]="product.raw_material_product">
                            <ng-option disabled selected value="0">Select Row Material Product</ng-option>
                            <ng-option *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown" [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                        </ng-select>
                    </div>

                    <div class="form-group col-lg-3">
                        <label>Given Qty<span class="text-danger">*</span></label>
                        <input type="number" class="form-control" [(ngModel)]="product.given_qty" name="price_{{product.id}}" placeholder="Price" />
                    </div>

                    <div class="form-group  col-lg-1">
                        <br/>
                        <button type="button" class="btn btn-danger" (click)="remove(i)">
                                            <i  data-feather="trash"></i>
                                     </button>
                    </div>
                </div>
                <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">
                        <div class="form-group col-lg-4">
                            <label class="form-label">Given Date<span class="text-danger">*</span></label>
                            <input type="text" readonly formControlName="given_date" id="given_date" placeholder="dd-mm-yyyy" class="form-control" (click)="basicDP.toggle()" tabindex="1" [(ngModel)]="basicDPdata" ngbDatepicker #basicDP="ngbDatepicker" aria-describedby="require_date"
                                [ngClass]="{ 'is-invalid': submitted && f.require_date.errors }">
                            <div *ngIf="submitted && f.require_date.errors" class="invalid-feedback">
                                <div *ngIf="f.require_date.errors.required">Given Date is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-8  text-end">
                            <label class="form-label">&nbsp;</label><br/>
                            <button type="button" class="btn btn-outline-success mb-2 float-right" (click)="addMore()"><i  data-feather="plus"></i> Add More</button>
                        </div>

                        <div class="form-group col-lg-12">
                            <div class="form-group">
                                <label for="register-description" class="form-label">Description </label>
                                <textarea rows="5" formControlName="description" class="form-control" placeholder="Ex. Stock Given To Team-A Leader Mr. Raj Patel" aria-describedby="register-description" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                            </div>
                        </div>
                        <div class="col col-sm-12 col-xs-12 col-lg-12">
                            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                <div class="alert-body">
                                    <p>{{ error }}</p>
                                </div>
                            </ngb-alert>
                        </div>
                        <div class="col col-sm-6 col-xs-6 col-lg-12 pt-1 text-right">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" tabindex="6" rippleEffect>
                                Close
                            </button>
                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="5" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>
                        </div>

                    </div>
                </form>

            </div>
        </div>
    </div>

</ng-template>
<!-- / Modal -->

<!-- Modal -->
<ng-template #modalBasicDetails let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modalBasicDetailsLabel">Stock Given Detail</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row overflow-auto">
            <div class="col-12">
                <div class="overlay" *ngIf="progressDetails">
                    <div class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <table class="w-100">
                    <tbody>
                        <tr>
                            <td class="p-50">
                                <h5>Given Batch : <b>{{ showDetails.given_batch }}</b></h5>
                            </td>
                            <td class="text-right p-50">
                                <h5>Given Date : <b>{{ showDetails.given_at }}</b></h5>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Given Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="showDetails.meta_dtails?.length != 0">
                                        <tr *ngFor="let givenMeta of showDetails.meta_dtails; let ndx = index">
                                            <td>{{ ndx+1 }}</td>
                                            <td>{{ givenMeta.raw_material.product_name }}</td>
                                            <td>{{ givenMeta.given_qty }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="p-50">
                                <h5><b>Description :</b></h5>
                                {{ showDetails.extra_remarks }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</ng-template>
<!-- / Modal -->
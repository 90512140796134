<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Vendor List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-3 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>

                                <div class="col-md-6 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Name / Mobile..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getVendorFilter(searchByName)">GO!</button>
                                    </div>
                                </div>

                                <div class="col-md-3 col-3 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="addVendor()">
                                      <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                      ><span class="d-none d-sm-inline-block">Add New</span>
                                    </button>

                                    </div>
                                </div>

                                <div class="col-12 d-sm-none d-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Name / Mobile..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getVendorFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>PHONE NO</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Payment Condition </th>
                                            <th>GST No</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="vendorsList?.length != 0">
                                        <tr *ngFor="let vendor of vendorsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ vendor.name }}</td>
                                            <td>{{ vendor.mobile }}
                                                <span *ngIf="vendor.mobile_two != '' && vendor.mobile_two != null"> / </span> {{ vendor.mobile_two }}
                                                <span *ngIf="vendor.mobile_three != '' && vendor.mobile_three != null"> / </span> {{ vendor.mobile_three }}
                                            </td>
                                            <td>{{ vendor.email }}</td>
                                            <td>{{ vendor.address }}</td>
                                            <td>{{ vendor.paymentCondition }}</td>
                                            <td>{{ vendor.gst_no }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" routerLink="/vendor/edit/{{ vendor.id }}"> <i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <!-- (click)="toggleSidebar('new-sidebar',vendor.id,ndx)" -->
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(vendor.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="vendorsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>



<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Vendor</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">

                                <div class="form-group">
                                    <label for="register-vendorName" class="form-label">Vendor Name<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="vendorName" class="form-control" placeholder="Vendor Name" aria-describedby="register-Vendor" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.vendorName.errors }" />
                                    <div *ngIf="submitted && f.vendorName.errors" class="invalid-feedback">
                                        <div *ngIf="f.vendorName.errors.required">Vendor Name is required</div>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label for="register-vendorAddress" class="form-label">Address<span class="text-danger">*</span> </label>
                                    <textarea rows="3" formControlName="vendorAddress" class="form-control" placeholder="Address" aria-describedby="register-vendorAddress" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.vendorAddress.errors }"></textarea>
                                    <div *ngIf="submitted && f.vendorAddress.errors" class="invalid-feedback">
                                        <div *ngIf="f.vendorAddress.errors.required">Address is required</div>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label for="register-vendorMobile" class="form-label">Mobile 1<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="vendorMobile" class="form-control" placeholder="Mobile" aria-describedby="register-vendorMobile" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.vendorMobile.errors }" />
                                    <div *ngIf="submitted && f.vendorMobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.vendorMobile.errors.required">Mobile is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-vendorMobileTwo" class="form-label">Mobile 2</label>
                                    <input type="text" formControlName="vendorMobileTwo" class="form-control" placeholder="Alternet Mobile" aria-describedby="register-vendorMobileTwo" tabindex="4" />
                                </div>

                                <div class="form-group">
                                    <label for="register-vendorMobileThree" class="form-label">Mobile 3</label>
                                    <input type="text" formControlName="vendorMobileThree" class="form-control" placeholder="Alternet Mobile 2" aria-describedby="register-vendorMobileThree" tabindex="5" />
                                </div>


                                <div class="form-group">
                                    <label for="register-vendorEmail" class="form-label">Email</label>
                                    <input type="text" formControlName="vendorEmail" class="form-control" placeholder="Email" aria-describedby="register-vendorEmail" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.vendorEmail.errors }" />
                                    <div *ngIf="submitted && f.vendorEmail.errors" class="invalid-feedback">
                                        <div *ngIf="f.vendorEmail.errors.required">Email is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-vendorGSTno" class="form-label">GST</label>
                                    <input type="text" formControlName="vendorGSTno" class="form-control" placeholder="GST Details" aria-describedby="register-vendorGSTno" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.vendorGSTno.errors }" />
                                    <div *ngIf="submitted && f.vendorGSTno.errors" class="invalid-feedback">
                                        <div *ngIf="f.vendorGSTno.errors.required">GST No. is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-vendorPaymentCondition" class="form-label">Payment Condition</label>
                                    <textarea rows="3" formControlName="vendorPaymentCondition" class="form-control" placeholder="Payment Condition" aria-describedby="register-vendorPaymentCondition" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.vendorPaymentCondition.errors }"></textarea>
                                </div>


                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                    <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Cancel </span>                                    
                              </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">


                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->
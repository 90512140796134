<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Productions List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25"  [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pb-1 pb-md-0 w-100">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="modalOpen(modalBasic)">
                                      <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                      ><span class="d-none d-sm-inline-block">Add New</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Photo</th>
                                            <th>Operator Name</th>
                                            <th>Machine</th>
                                            <th>Product</th>
                                            <th>Require Qty </th>
                                            <th>Ok Qty </th>
                                            <th>Reject Qty</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="submitList?.length != 0">
                                        <tr *ngFor="let submit of submitList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td *ngIf="submit.chart_photo_thum != null &&  submit.chart_photo_thum != ''">
                                                <img [src]="submit.chart_photo_thum" height="40" width="40" alt="IMG" class="rounded mr-75">
                                            </td>
                                            <td *ngIf="submit.chart_photo_thum == null || submit.chart_photo_thum == ''">-</td>

                                            <td *ngIf="submit.operator_name != null">{{ submit.operator_name }}</td>
                                            <td *ngIf="submit.operator_name == null">-</td>

                                            <td *ngIf="submit.machine_name != null">{{ submit.machine_name }} ({{ submit.machine_number }})</td>
                                            <td *ngIf="submit.machine_name == null">-</td>

                                            <td>{{ submit.product_name }}</td>
                                            <td>{{ submit.required_qty }}</td>
                                            <td>{{ submit.ok_qty }}</td>
                                            <td>{{ submit.reject_qty }}</td>
                                            <td>{{ submit.added_at }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="submitList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Production Submit</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="overlay" *ngIf="progressMachinesDropDown && progressOperatorsDropDown && progressGivenBatchsDropDown">
                <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="col-12 pt-1 overflow-auto">
                <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <label for="register-batch_no" class="form-label">Select Batch No <span class="text-danger">*</span></label>
                            <ng-select formControlName="batch_no" (change)="selectGivenBatch($event)" aria-describedby="register-batch_no" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.batch_no.errors }">
                                <ng-option [value]="" selected disabled>Select Batch No</ng-option>
                                <ng-option *ngFor="let givenBatch of givenBatchsDropDown" [value]="givenBatch.id">{{givenBatch.batch_no}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.batch_no.errors" class="invalid-feedback">
                                <div *ngIf="f.batch_no.errors.required">Batch No is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="register-shift" class="form-label">Select Shift </label>
                            <ng-select formControlName="shift" aria-describedby="register-shift" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.shift.errors }">
                                <ng-option [value]="" selected disabled>Select Shift</ng-option>
                                <ng-option value="D">Day Shift</ng-option>
                                <ng-option value="N">Night Shift</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.shift.errors" class="invalid-feedback">
                                <div *ngIf="f.shift.errors.required">Shift is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="register-operator_name" class="form-label">Operator Name</label>
                            <ng-select formControlName="operator_name" aria-describedby="register-operator_name" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.operator_name.errors }">
                                <ng-option [value]="" selected disabled>Select Operator</ng-option>
                                <ng-option *ngFor="let operator of operatorsDropDown" [value]="operator.id">{{operator.operator_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.operator_name.errors" class="invalid-feedback">
                                <div *ngIf="f.operator_name.errors.required">Operator is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 ">
                            <label for="register-machine_name" class="form-label">Machine Name</label>
                            <ng-select formControlName="machine_name" aria-describedby="register-machine_name" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.machine_name.errors }">
                                <ng-option [value]="" selected disabled>Select Machine</ng-option>
                                <ng-option *ngFor="let machine of machinesDropDown" [value]="machine.id">{{machine.machine_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.machine_name.errors" class="invalid-feedback">
                                <div *ngIf="f.machine_name.errors.required">Machine Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="register-raw_material_product" class="form-label">Raw Material Product<span class="text-danger">*</span></label>
                            <input type="text" readonly formControlName="raw_material_product" class="form-control" placeholder="Raw Material Product" aria-describedby="register-raw_material_product" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.raw_material_product.errors }"
                            />
                            <div *ngIf="submitted && f.raw_material_product.errors" class="invalid-feedback">
                                <div *ngIf="f.raw_material_product.errors.required">Raw Material Product is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-2">
                            <label for="register-required_qty" class="form-label">Required Qty<span class="text-danger">*</span></label>
                            <input type="text" readonly formControlName="required_qty" class="form-control" placeholder="Required Qty" aria-describedby="register-required_qty" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.required_qty.errors }" />
                            <div *ngIf="submitted && f.required_qty.errors" class="invalid-feedback">
                                <div *ngIf="f.required_qty.errors.required">Required Qty is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-2">
                            <label for="register-ok_qty" class="form-label">Ok Qty<span class="text-danger">*</span></label>
                            <input type="text" formControlName="ok_qty" class="form-control" placeholder="Ok Qty" aria-describedby="register-ok_qty" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.ok_qty.errors }" />
                            <div *ngIf="submitted && f.ok_qty.errors" class="invalid-feedback">
                                <div *ngIf="f.ok_qty.errors.required">Ok Qty is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-2">
                            <label for="register-reject_qty" class="form-label">Reject Qty<span class="text-danger">*</span></label>
                            <input type="text" formControlName="reject_qty" class="form-control" placeholder="Reject Qty" aria-describedby="register-reject_qty" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.reject_qty.errors }" />
                            <div *ngIf="submitted && f.reject_qty.errors" class="invalid-feedback">
                                <div *ngIf="f.reject_qty.errors.required">Reject Qty is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-9">

                            <div class="form-group ">
                                <label for="register-remarks" class="form-label">Select Remarks</label>
                                <ng-select formControlName="remarks" aria-describedby="register-remarks" tabindex="9">
                                    <ng-option [value]="" selected disabled>Select Remarks</ng-option>
                                    <ng-option *ngFor="let remark of remarksDropDown" [value]="remark.id">{{remark.remark_title}}</ng-option>
                                </ng-select>

                            </div>

                            <div class="form-group">
                                <label for="register-extra_remarks" class="form-label">Other Remarks</label>
                                <textarea rows="5" formControlName="extra_remarks" class="form-control" placeholder="Other Remarks" aria-describedby="register-extra_remarks" tabindex="10" [ngClass]="{ 'is-invalid': submitted && f.extra_remarks.errors }"></textarea>
                            </div>
                        </div>

                        <div class="form-group col-lg-3">
                            <label for="register-photo" class="form-label">Upload Chart </label>

                            <div class="card card-upload">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <img [src]="selected_photo_AvatarImage" alt="Upload" class="img-fluid">
                                        </div>
                                        <div class="col-12">
                                            <input class="form-control" type="file" id="photo" (change)="uploadFile($event)" name="photo" formControlName="photo" tabindex="11" [ngClass]="{ 'is-invalid': submitted && f.photo.errors }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col col-sm-12 col-xs-12 col-lg-12">
                            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                <div class="alert-body">
                                    <p>{{ error }}</p>
                                </div>
                            </ngb-alert>
                        </div>

                        <div class="col col-sm-6 col-xs-6 col-lg-12 pt-1 text-right" *ngIf="!saveTransfer">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" tabindex="13" rippleEffect>
                                Close
                            </button>
                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="12" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>
                            <button [disabled]="loading" type="button" (click)="transfer()" class="btn btn-primary ml-2" tabindex="13" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save & Stock Transfer
                            </button>
                        </div>

                        <div class="form-group col-lg-12" *ngIf="saveTransfer">
                            <label for="register-transfer_department_id" class="form-label">Select Department<span class="text-danger">*</span></label>
                            <ng-select formControlName="transfer_department_id" appendTo="body" aria-describedby="register-transfer_department_id" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.operator_id.errors }">
                                <ng-option [value]="" selected disabled>Select Department</ng-option>
                                <ng-option *ngFor="let department of departmentsDropDown" [value]="department.id">{{department.name}}</ng-option>
                            </ng-select>

                        </div>
                        <div class="form-group col-lg-12 text-right" *ngIf="saveTransfer">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" tabindex="13" rippleEffect>
                                Close
                            </button>
                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="12" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</ng-template>
<!-- / Modal -->
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class ManufacturersService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }
  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-manufacturers`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}delete-manufacturer/` + id,)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  manufacturerViewDetails(id: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}show-details-manufacturer/` + id)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PurchaseRequisitionService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-purchase-requisition`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDataMakePO(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-make-po-purchase-requisition`,'')
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getDataFinalPO(data): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-final-po-purchase-requisition`,{data})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  makePOfromPR(data): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}make-po-from-purchase-requisitio`,{data})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(formdata: any, id: any): Observable<any> {

    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-purchase-requisition`, formdata)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-purchase-requisition`,  formdata )
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }
  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-purchase-requisition`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  changeStatus(id,status): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-status-purchase-requisition`, { id,status })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
